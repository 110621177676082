.profileContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 24px;

  }
  
  .profileIcon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-bottom: 15px;
}
  
  .profileiInfo {
    display: flex;
    flex-direction: column;
  }
  
  .profilenName {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #000;
    margin-bottom: 5px;
  }
  
  .profileRole {
    font-size: 12px;
    color: #858181;
    justify-content: center;
    text-align: center;
    display: flex;
  }

  .SidebarBottom {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .BlueLogoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
  }

  .blueLogo {
    bottom: 0;
    width: 35px;
    height: 60px;
    align-self: center;
  }

  .spacer {
    margin: 27vh;
    flex-grow: 10;
  }