.mainContent {
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 24px;
  overflow: auto;
  height: calc(100% - 120px);
}

.mainContent::-webkit-scrollbar {
  width: 9px;
}

.mainContent::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.mainContent::-webkit-scrollbar-thumb {
  background-color: #3C96D4;
  border-radius: 5px;
}

.topSectionDiv,
.bottomSectionDiv {
  display: flex;
  flex-direction: row;
}

.topSectionDiv {
  height: 368.6px;
}

.bottomSectionDiv {
  height: calc(100% - 368.6px);
}

.LogEmployeeSicknessSection,
.OpenSicknessSection,
.SicknessOverviewSection,
.SicknessLogSection,
.ReviewLogSection {
  display: flex;
  flex-direction: column;
}

.NumAndQandA,
.encompas,
.nameandans {
  display: flex;
  flex-direction: row;
}

.encompas {
  width: 100%;
}

.halfie {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.QuestionSection {
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: bold;
  border-bottom: 2px solid #000000;
}

.GrayNum,
.GrayQuestion {
  font-size: 12px;
  color: #949494;
}

.GrayQuestion {
  margin-bottom: 15px;
}

.BlackAnswer {
  margin-bottom: 20px;
  white-space: pre-line;
  font-size: 13px;
}

.BlackAnswerdif {
  font-size: 13px;
  width: 500px;
  overflow-wrap: break-word;
}

.NAME {
  margin-bottom: 20px;
  white-space: wrap;
  font-size: 13px;
  width: 120px;
}

.GrayNum {
  margin-right: 12px;
}

.GrayNumDif {
  margin-bottom: 12px;
}

.ThreeSmallBoxesSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.ReviewLogSection {
  flex: 1;
  height: calc(100% - 25px);
}

.OpenSicknessSection {
  width: 50%;
}

.KPIsActionsNotes {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.KPIsActionsNotesBoxes {
  margin-right: 25px;
  margin-right: 25px;
  width: 40%;
}

.SicknessOverviewSection {
  display: flex;
  flex-grow: 1;
}

.LogEmployeeSicknessSection {
  width: calc(260px + 72px);
}

.SicknessLogSection {
  flex-grow: 1;
}

.title {
  font-family: 'Baloo Bhai', cursive;
  font-weight: bold;
  font-size: 24px;
  margin-top: 27px;
  margin-bottom: 20px;
  width: 100%;
  white-space: nowrap;
}

.TopSection,
.KPIsActionsNotes {
  display: flex;
  flex-direction: row;
}

.ReviewLogTable {
  height: calc(100% - 25px - 86px);
  /* -86px because thats the height of the title 'Review Log'*/
  width: 100%;
  margin: 0 24px 0 0;
  border: solid 2px #ECEEF6;
  border-radius: 8px;
  background-color: white;
}

.OpenSicknessTable,
.SicknessOverviewTable,
.SicknessLogTable {
  height: 200px;
  width: calc(100% - 25px);
  margin: 0 24px 0 0;
  border: solid 2px #ECEEF6;
  border-radius: 8px;
  background-color: white;
}

.CurrentKPIsBox {
  padding: 25px;
  min-height: 150px;
  width: calc(100% - 50px);
  margin: 0 24px 0 0;
  border: solid 2px #ECEEF6;
  border-radius: 8px;
  background-color: white;
}

.KPI {
  height: 30%;
  font-size: 16px;
}

.tdNoInfo {
  padding-top: 15px;
  text-align: center;
  color: #4a60a8;
  font-weight: bold;
}

.SicknessLogTable {
  margin-right: 0px;
  width: 100%;
}

.SicknessOverviewTable,
.SicknessLogTable {
  flex-grow: 1;
  margin-bottom: 24px;
  overflow: auto;
  height: 10px;
}

.LogEmployeeSicknessForm {
  width: calc(260px + 45px);
  padding: 24px;
  padding-left: 23px;
  padding-right: 0px;
  border: solid 2px #ECEEF6;
  border-radius: 8px;
  background-color: white;
}

.FormTextAndInputSection {
  display: flex;
  width: 130px;
  margin-right: 23px;
  flex-direction: column;
}

.FormRowSection {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.FormInput {
  width: 130px;
  margin-bottom: 19px;
}

.FormText {
  color: #949494;
  font-size: 12px;
  margin-bottom: 10px;
}

.bookingLeaveComment {
  padding-left: 4px;
  padding-right: 4px;
  font-size: 12px;
  width: 276px;
  height: 40px;
  resize: none;
  border-radius: 9px;
  border: 1px solid #3C96D4;
  font-family: 'PT Sans';
  -webkit-transition: 0.5s;
  transition: 0.2s;
  outline: #D9D9D9;
}

.nonBookingLeaveComment {
  padding-left: 4px;
  padding-right: 4px;
  font-size: 12px;
  width: 276px;
  height: 40px;
  resize: none;
  border-radius: 9px;
  border: 1px solid #3C96D4;
  font-family: 'PT Sans';
  -webkit-transition: 0.5s;
  transition: 0.2s;
  outline: #D9D9D9;
  color: #949494;
}

.bookingLeaveComment:focus {
  border: 1px solid #c4c4c4;
}

.bookingLeaveComment::placeholder {
  color: #D9D9D9;
}

.bookingLeaveRowComment {
  height: 47px;
  width: 282px;
  margin-bottom: 19px;
}

.SubmitButton {
  justify-content: center;
  align-content: center;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  background-color: #3C96D4;
  border: none;
  border-radius: 6px;
  width: 83px;
  height: 25px;
  line-height: 25px;
  cursor: pointer;
}

.SubmitButton:hover {
  background-color: #2a6d9e;
}

.submitdiv {
  justify-content: center;
  align-content: center;
  text-align: center;
  margin: none;
  padding: none;
}

.LoadOverlay {
  background-color: rgba(0, 0, 0, 0.4);
  /* Semi-transparent background */
  position: fixed;
  /* Cover the entire screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  /* Ensure it's above everything else */
  display: flex;
  justify-content: center;
  /* Center the content horizontally */
  align-items: center;
  /* Center the content vertically */
}

.holidayDaysNum {
  font-family: 'Baloo Bhai', cursive;
  font-weight: bold;
  font-size: 64px;
  margin-right: 15px;
  font-family: 'PT Sans', sans-serif;
  flex-grow: 1;
  text-align: center;
}

.holidayDays {
  display: flex;
  background-color: #FFFFFF;
  border: 2px solid #ECEEF6;
  max-width: 250px;
  min-width: 140px;
  height: 97px;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 13px;
  justify-content: center;
  align-items: center;
}

.holidayDaysText {
  word-wrap: break-word;
  font-family: 'Baloo Bhai', cursive;
  font-size: 20px;
}

.studyDaysNum {
  text-align: center;
  font-family: 'Baloo Bhai', cursive;
  font-weight: bold;
  font-size: 64px;
  min-width: 35px;
  margin-right: 8px;
  font-family: 'PT Sans', sans-serif;
  flex-grow: 1;
}

.studyDays {
  display: flex;
  background-color: #FFFFFF;
  border: 2px solid #ECEEF6;
  max-width: 250px;
  min-width: 140px;
  height: 97px;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 13px;
  margin-left: 20px;
  align-items: center;
  justify-content: center;
}

.studyDaysText {
  word-wrap: break-word;
  font-family: 'Baloo Bhai', cursive;
  font-size: 20px;
}

.sicknessInfo,
.numBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sicknessInfo {
  flex-direction: row;
  margin-bottom: 25px;
}

.numBox {
  flex-direction: row;
  width: 200px;
  height: 100px;
  background-color: white;
  border-radius: 14px;
}

.number {
  font-size: 64px;
  font-weight: bold;
  width: 50%;
  text-align: center;
}

.numBoxText {
  font-size: 14px;
  width: 50%;
  padding-right: 15px;
  font-family: 'Baloo Bhai', cursive;
}

.takenNumBoxText {
  font-size: 14px;
  width: 50%;
  padding-left: 15px;
  font-family: 'Baloo Bhai', cursive;
}

.resizeHandle {
  cursor: ew-resize;
  width: 10px;
  height: 100%;
  background-color: grey;
  float: right;
}

.BackBtn {
  margin-top: 44px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.BackBtn:hover {
  transform: scale(1.1);
}

.BackBtn img {
  width: 100%;
  height: 90%;
}

.infoSector {
  width: calc(100vw - 270px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 12px;
  border-radius: 6px;
  margin-left: 19px;
}

.YesNoTicks {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  font-size: 13px;
}