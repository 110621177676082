.raise-content {
  /* padding: 20px; */
  height: 100vh;
  width: 100%;
}
.button-primary {
  background: #3c96d4;
  width: inherit;
  padding-inline: 30px;
  height: 25px;
  border-radius: 8px;
  color: white;
  text-align: center;
  font-family: "PT Sans", sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 25px;
}

.button-secondary {
  border: 2px solid #3c96d4;
  width: inherit;
  padding-inline: 30px;
  height: 25px;
  border-radius: 8px;
  background-color: white;
  color: #3c96d4;
  text-align: center;
  font-family: "PT Sans", sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 25px;
  cursor: pointer;
}

.mr20 {
  margin-right: 20px;
}
.ml20 {
  margin-left: 20px;
}
.card {
  background: #fff;
  padding: 10px;
}

.flex-input {
  display: flex;
}

.dataTitleV2 {
  font-family: "PT Sans", sans-serif;
  color: #949494;
  font-size: 12px;
  text-align: right;
  padding: 2px 0px 10px 0px;
  max-height: 100px;
  margin-bottom: 10px;
}

.dataTitle {
  font-family: "PT Sans", sans-serif;
  color: #000;
  font-size: 16px;
  font-weight: bold;
  padding: 0px 0px 0px 0px;
  max-height: 100px;
  margin: 0px;
}

.largeText {
  font-size: 16px;
  margin: 0px;
}
.basicMargin {
  margin: 20px;
}

.logBookDropdown {
  width: 150px;
  margin-top: 10px;
}
.fullWidth {
  width: 100%;
}
