.TimesheetsMainContent {
    display: flex;
    flex-direction: column;
    padding-left: 24px;
    height: calc(100vh - 24px);
    /* Adjust the value based on the heights of the top navigation bar and any other elements that might affect the height */
    overflow: auto;
    overflow-x: hidden;
}

.no-style-link {
    color: inherit;
    text-decoration: underline;
}

.TimesheetsScrollableArea {
    height: 100%;
    overflow: auto;
    padding-right: 15px;
}

.TimesheetsScrollableArea::-webkit-scrollbar {
    width: 9px;
}

.TimesheetsScrollableArea::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

.TimesheetsScrollableArea::-webkit-scrollbar-thumb {
    background-color: #3C96D4;
    border-radius: 5px;
}

.TimesheetsTitleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: 130px;
}

.TimesheetsTitle {
    font-family: 'Baloo Bhai', cursive;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 20px;
}

.ExportBtn {
    width: 83px;
    height: 25px;
    flex-shrink: 0;
    margin-right: 47px;
    margin-bottom: 5px;

    border-radius: 8px;
    background: #3C96D4;

    color: white;
    text-align: center;

    font-family: 'PT Sans', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;

    cursor: pointer;
}

.TableLayout {
    margin-bottom: 20px;
}

.TimesheetsSubTitle {
    font-family: 'PT Sans', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.StickyHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    margin-bottom: 20px;
}

.MaxTotal {
    align-items: center;
}

.TableLayout {
    width: 100%;
    /* height: 317px; */
    flex-shrink: 0;

    border-radius: 6px;
    border: 2px solid #ECEEF6;
    background: #FFF;
}

.TimesheetTotals {
    width: 66%;
    height: 40px;
    flex-shrink: 0;

    border-radius: 6px;
    border: 2px solid #ECEEF6;
    background: #FFF;

}

.TimesheetPagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;

    /* padding-right: 24px; */
}

.TimesheetButtons,
.SearchContainer {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: right;
    width: 70%;
}

.SearchContainer {
    padding-right: 24px;
}

.PaginationDisplay {
    width: 83px;
    height: 25px;
    flex-shrink: 0;
    margin-inline: 10px;
    margin-bottom: 5px;

    border-radius: 8px;
    border: 2px solid #3C96D4;
    background: #FFF;

    color: #3C96D4;
    text-align: center;

    font-family: 'PT Sans', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;

    cursor: pointer;
}

.TimesheetsTable {
    width: 100%;
    border-collapse: collapse;
    table-layout: initial;
    table-layout: fixed;
    margin-top: 11.25px;
}

.EmptyCell,
.Total,
.LastEmptyCell {
    flex-grow: 0;
    /* Prevents the cells from growing */
    flex-shrink: 0;
    /* Prevents the cells from shrinking */
}

.TimesheetTotals .TimesheetsTable {
    max-width: 100%;
    margin-block: 11.25px;
}

.TimesheetTotals .TimesheetsTable .EmptyCell {
    width: 8%;
}

.TimesheetTotals .TimesheetsTable .TotalTxt {
    width: 21.2%;
    text-align: left;
}

.TimesheetTotals .TimesheetsTable .MaxTotal {
    width: 8.3%;
}

.MaxTotal.red {
    color: red;
}

.MaxTotal.blue {
    color: #3C96D4;
}

.MaxTotal.green {
    color: #0B8A00;
}


.TimesheetTotals .TimesheetsTable .TotalDay {
    width: 8.1%;
}

.TimesheetTotals .TimesheetsTable .Total {
    max-width: calc(100% - 25%);
}

.TimesheetsTable .Total {
    color: #3C96D4;
}

.TimesheetsTable table {
    width: 100%;
    max-width: 100%;
    /* Add this line */
    border-collapse: collapse;
    table-layout: initial;
}

.TimesheetsTable th {
    text-align: center;

    color: #949494;
    font-family: 'PT Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-bottom: 1px solid #ECEEF6;
}

.TimesheetsTable thead th {
    position: sticky;
    /* make the table heads sticky */
    top: 0px;
    /* table head will be placed from the top of the table and sticks to it */
    background: #ffffff;
    overflow: hidden;
    z-index: 2;
}

.TimesheetsTable th.jobNo {
    width: 5.5%;
}

.cell-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    padding-right: 20px;
}

.expanded-arrow,
.cell-text {
    flex-shrink: 0;
}

.expanded-arrow {
    margin-right: 7px;
    border: solid;
    border-width: 0 2px 2px 0;
    border-radius: 1px;
    padding: 2px;
    pointer-events: none;
    border-color: #333;
    transform: rotate(-45deg);
}

.expanded-arrow.rotated {
    transform: translateY(-50%) rotate(45deg);
    /* Rotated by 180 degrees */
    margin-top: 4px;
}

.TimesheetsTable th.address {
    width: 14%;
    text-align: left;
}

.TimesheetsTable th.day {
    width: 5.5%;
}

.dayWrapper {
    text-align: center;
}

.TimesheetsTable th.level {
    width: 5%;
}

.TimesheetsTable th.client {
    width: 14%;
    text-align: left;
}

.TimesheetsTable th.tags {
    flex-grow: 1;
    /* This will allow the tags column to take up the remaining width */
    text-align: left;
}

.TimesheetsTable td {
    text-align: center;
    padding-block: 4px;

    color: #000;
    font-family: 'PT Sans', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.12px;
}

.TimesheetsTable td.tdNoInfo {
    padding-top: 15px;
    text-align: center;
    color: #4a60a8;
    font-weight: bold;
}

.TableLayout .TimesheetsTable td {
    padding-block: 8px;
}

.TableLayout .TimesheetsTable td,
.TableLayout .TimesheetsTable th {
    border-bottom: 1px solid #ECEEF6;
}

.TableLayout .TimesheetsTable td:nth-child(2),
.TableLayout .TimesheetsTable td:nth-child(11),
.TableLayout .TimesheetsTable td:nth-child(12) {
    text-align: left;
}

.TableLayout .TimesheetsTable td:nth-child(11) .Tag {
    background-color: #F37021;
    color: #FFFFFF;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    padding-inline: 15px;
    max-width: 8px;
    height: 25px;
    text-align: center;
    justify-content: center;
    display: flex;
    /* Add this to use flexbox */
    align-items: center;
}

.expanded-address {
    padding-left: 20px;
    /* Adjust this value as needed */
}

.no-padding {
    padding: 0 !important;
}


.PostAllBtn {
    width: 83px;
    height: 25px;
    flex-shrink: 0;
    margin-right: 24px;
    margin-bottom: 5px;

    border-radius: 8px;
    background: #0B8A00;

    color: white;
    text-align: center;

    font-family: 'PT Sans', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;

    cursor: pointer;
}

.AddBtn {
    width: 83px;
    height: 25px;
    flex-shrink: 0;
    margin-right: 24px;
    margin-bottom: 5px;

    border-radius: 8px;
    background: #3C96D4;

    color: white;
    text-align: center;

    font-family: 'PT Sans', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;

    cursor: pointer;
}

.penIconContainer {
    display: flex;
    align-items: center;
    /* Center items vertically */
    justify-content: center;
    /* Center items horizontally */
}

.numberScroller {
    margin-left: 16px;
    /* Apply a 10px margin-left to all direct children */
}

.penIcon {
    display: flex;
    /* Use flex to align the image vertically */
    align-items: center;
    /* Center the image vertically */
}

.penIcon img {
    margin: 0;
    /* Reset margin for the image */
    filter: grayscale(100%);
    /* Full grayscale to make it grey */
    opacity: 0.75;
    /* Adjust if you want to make the icon less prominent */
}



.backGround {
    position: fixed;
    /* Fixed positioning makes the div stick to the viewport */
    top: 0;
    /* Aligns the div with the top of the page */
    left: 0;
    /* Aligns the div with the left of the page */
    width: 100vw;
    /* Sets the width to the full width of the viewport */
    height: 100vh;
    /* Sets the height to the full height of the viewport */
    z-index: 999990;
    /* Ensures that the div is displayed above all other content on the page */
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.form {
    display: flex;
    flex-direction: column;
    width: 360px;
    background-color: #EAF2F9;
    justify-content: center;
    align-items: center;
    z-index: 999990;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 8px;
}

.FormRowSection {
    display: flex;
    flex-direction: row;
}

.FormTextAndInputSection {
    display: flex;
    width: 130px;
    margin-right: 23px;
    flex-direction: column;
}

.FormInput {
    width: 309px;
    height: 67px;
    margin-bottom: 19px;
}

.FormText {
    color: #949494;
    text-align: center;
    font-family: 'PT Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}