/* ModalStyles.module.css */

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: #eaf2f9;
  padding: 25px;
  border-radius: 8px;

  width: 300px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.commentModal_commentSection {
  margin-block: 25px;
  height: 67px;
}

.commentModal_teamCommentSection {
  height: 67px;
}

.commentModal_feeSection {
  width: 100%; /* Take the full width to center the child */
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  color: #292929;
  text-align: center;
  font-family: "PT Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.feeInput {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.blueLogo {
  width: 25px;
  height: 40px;
  padding-bottom: 20px;
}

.MomentLogo {
    font-size: 36px;
    font-family: 'Baloo Bhai', cursive;
    font-weight: bold;
    color: #3C96D4;
}