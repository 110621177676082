.ProfileMainContent {
    display: flex;
    flex-direction: column;
    padding-left: 24px;
    padding-right: 22px;
    margin-right: 2px;
    height: calc(100vh - 80px);
    /* Adjust the value based on the heights of the top navigation bar and any other elements that might affect the height */
    overflow: auto;
}

.ProfileMainContent::-webkit-scrollbar {
    width: 9px;
}

.ProfileMainContent::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

.ProfileMainContent::-webkit-scrollbar-thumb {
    background-color: #3C96D4;
    border-radius: 5px;
}

.title {
    font-family: 'Baloo Bhai', cursive;
    font-weight: bold;
    font-size: 24px;
    margin-top: 27px;
}

.title2 {
    font-family: 'Baloo Bhai', cursive;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 28px;
}

.title3 {
    font-family: 'Baloo Bhai', cursive;
    font-weight: bold;
    font-size: 24px;
    color: #D9D9D9;
}

.trainingManagement {
    margin-bottom: 28px;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-family: 'PT Sans', sans-serif;
}

.trainingTimeComment {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Disclaimer {
    font-family: 'PT Sans', sans-serif;
    color: #949494;
    font-size: 12px;
    font-weight: 700;
}