.button {
  width: 60px;
  height: 25px;
  border-radius: 8px;
  color: white;
  text-align: center;
  font-family: 'PT Sans', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}
  
.input {
  width: 73px;
  height: 25px;

  border-radius: 8px;
  border: 1px solid #3C96D4;
  background: #FFF;
  
  margin-left: 10px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
  