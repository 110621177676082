.progressBar {
  width: 100%;
  background: #f3f3f3; /* ALT: lightgrey */
  position: relative;
  overflow: hidden;
  height: 20px;
  border-radius: 10px;
}

.progressBar__colorFill {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: width 0.5s ease;
  border-radius: 10px; 
}

.progressBar__text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  color: black;
}

.green {
  background-color: rgba(11, 138, 0, 0.5);
}

.yellow {
  background-color: rgba(255, 202, 40, 0.5);
}

.orange {
  background-color: rgba(243, 112, 33, 0.5);
}

.red {
  background-color: rgba(255, 0, 0, 0.5);
}

.blueExtraLight {
  background-color: rgba(60, 150, 212, 0.2); /* extra light blue */
}

.blueLight {
  background-color: rgba(60, 150, 212, 0.4); /* light blue */
}

.blueMedium {
  background-color: rgba(60, 150, 212, 0.6); /* a lighter shade of blue */
}

.blueDark {
  background-color: rgba(60, 150, 212, 0.8); /* steel blue */
}

.blueDarker {
  background-color: rgba(60, 150, 212, 1); /* steel blue */
}
