.box, .boxNotUsable {
  height: 23px;
  width: 23px;
  background-color: white;
  border: solid 1px #3C96D4;
  border-radius: 8px;
  cursor: pointer;
}

.boxNotUsable {
  border: solid 1px #949494;
}

.arrow {
  width: 1px;
  height: 7px;
  content: "";
  right: 12px;
  border: solid;
  border-width: 0 3px 3px 0;
  border-radius: 2px;
  padding: 2px;
}

.positionArrow {
  margin-right: 11px;
}