.SignInButton {
    background-color: #3C96D4;
    border-radius: 8px;
    font-size: 16px;
    padding: 15px;
    padding-top: 7.5px;
    padding-bottom: 7.55px;
    font-family: 'PT Sans', sans-serif;
    font-weight: bold;
    cursor: pointer;
    width: 83px;
    height: 25px;
    color: white;
    border: none;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease;
}

.SignInButton:hover {
    background-color: #2a6d9e;
    transform: scale(1.05);
}

.SignInButton:active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.2);
}

.BlueIcon {
    width: 49px;
    height: 78px;
    margin-bottom: 16px;
}

.MomentLogo {
    position: fixed; 
    top: 14px;
    right: 28px;
    z-index: 500; 
    font-size: 36px;
    font-family: 'Baloo Bhai', cursive;
    font-weight: bold;
    color: #3C96D4;
}

.LoginPage {
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.WelcomeBackText {
    color: var(--black, #000);
    text-align: center;
    font-size: 24px;
    font-family: Baloo Bhai;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    margin-bottom: 15px;
}

.LoginPageText {
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 15px;
}

.lightBlueBlock {
    position: absolute;
    height: 50%;
    width: 100%;
    background-color: #EAF2F9;
    z-index: 1; 
    bottom: 50%; 
}

.darkBlueBlock {
    position: absolute; 
    height: 50%;
    width: 100%;
    background-color: #3C96D4;
    z-index: 1; 
    top: 50%; 
}

.loginBox {
    position: absolute;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    font-size: 26px;
    padding: 24px;
    font-family: 'PT Sans', sans-serif;
    z-index: 2; 
    background-color: #ffffff;
    border: 1px solid #3C96D4;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
}