.Comment {
  padding-left: 7px;
  padding-right: 4px;
  font-size: 13px;
  font-weight: bold;
  width: calc(100% - 11px);
  height: 100%;
  resize: none;
  border-radius: 9px;
  border: 1px solid #3C96D4;
  font-family: 'PT Sans';
  -webkit-transition: 0.5s;
  transition: 0.2s;
  outline: #D9D9D9;
}

.Textfeild {
  padding-left: 7px;
  padding-right: 4px;
  font-size: 13px;
  font-weight: bold;
  width: calc(100% - 11px);
  height: 100%;
  resize: none;
  border-radius: 9px;
  border: 1px solid #3C96D4 !important;
  font-family: 'PT Sans';
  -webkit-transition: 0.5s;
  transition: 0.2s;
  outline: #D9D9D9;
  background: white !important;
}
.Comment:focus {
  border: 1px solid #c4c4c4;
}
.Comment::placeholder {
  color: #949494;
  font-family: "PT Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.12px;
}

@keyframes pulsate {
  0% {
    border-color: red;
  }
  50% {
    border-color: transparent;
  }
  100% {
    border-color: red;
  }
}


.invalidInput {
  /* copy all styles from .Comment, and add the animation */
  animation: pulsate 0.5s ease-in-out;
  animation-iteration-count: 1;
  border: 1px solid #3C96D4;
}

.invalidInputTextfeild {
  /* copy all styles from .Comment, and add the animation */
  animation: pulsate 0.5s ease-in-out;
  animation-iteration-count: 1;
  border: 1px solid red;
}
