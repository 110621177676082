.companyHolidayCalendar {
    font-family: 'Baloo Bhai', cursive;
    background-color: #FFFFFF;
    border: 2px solid #ECEEF6;
    border-radius: 6px;
    margin-bottom: 15px;
    display: flex;
    justify-content: top;
    align-items: center;
    flex-direction: column;
  }

.title {
    font-family: 'Baloo Bhai', cursive;
    font-weight: bold;
    font-size: 24px;
    color: #D9D9D9;
  }

.CalendarTable {
    width: calc(100% - 48px);
    font-size: 12px;
    font-weight: normal;
    border-collapse: collapse;
    margin-top: 12px;
    border-radius: 8px;
    margin-bottom: 24px;
  }

.CalendarTable td {
    border: solid 1px #bdbdbd;
    text-align: center;
    white-space: nowrap;
    padding: 0px;
    height: 25px;
    z-index: 2;
    flex-direction: row;
    position: relative;
  }

.CalendarTable th {
    width: 30px;
    font-weight: normal;
  }

.ResourceName {
    text-align: center;
}

.weekend {
    background-color: #e2e2e2;
}
.weekendText {
    color: #c2c2c2;
}

.presentDay {
    background-color: #DAF0FF;
}
.presentDayText {
    color: #3C96D4;
}

.fullBar {
  height: 15px; 
  margin-top: 5px;
  margin-bottom: 5px;
  position: relative;
  width: 105%;
  left: -1px;
  border: 1px solid #000000;
  border-left: none;
  border-right: none;
  border-collapse: collapse;
  z-index: 1;
    /* Size of each stripe */
    --stripe-size: 2px;

    /* Create a diagonal stripe pattern */
    background: repeating-linear-gradient(
        -45deg,
        #F37021,
        #F37021 var(--stripe-size),
        #ffffff var(--stripe-size),
        #ffffff calc(var(--stripe-size) * 2)
    );
}

.oneBar {
  height: 15px; 
  margin-top: 5px;
  margin-bottom: 5px;
  position: relative;
  width: 100%;
  left: -1px;
  border: 1px solid #000000;
  border-radius: 5px 5px 5px 5px;
  border-collapse: collapse;

}

.startBar {
  height: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  position: relative;
  width: 100%;
  left: -1px;
  border: 1px solid #000000;
  border-radius: 5px 0px 0px 5px;
  border-right: none;
  border-collapse: collapse;
}

.endBar {
  height: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  position: relative;
  width: 103%;
  left: -1px;
  border: 1px solid #000000;
  border-radius: 0px 5px 5px 0px;
  border-left: none;
  border-collapse: collapse;
}

.AMbar {
  height: 15px;
  margin-top: -8.25px;
  position: relative;
  width: 48%;
  left: -1px;
  border: 1px solid #000000;
  border-radius: 5px 5px 5px 5px;
  border-collapse: collapse;
  position: absolute;
  z-index: 1;
}

.PMbar {
  height: 15px;
  margin-top: -8.25px;
  position: relative;
  width: 48%;
  left: 48%;
  border: 1px solid #000000;
  border-radius: 5px 5px 5px 5px;
  border-collapse: collapse;
  position: absolute;
  z-index: 2;
}

.keys {
  display: flex;
  font-size: 12px;
}

.key {
  margin: 24px;
  margin-top: 0px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.BarKey {
  height: 15px;
  width: 40px;
  border: solid 1px #000000;
  border-radius: 5px 5px 5px 5px;
}

.ColumnKey {
  height: 25px;
  width: 40px;
  border: solid 1px #bdbdbd;
}

.keyName {
  margin-left: 5px;
}

.CalendarControlls {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
}

.Button {
  height: 22px;
  width: 22px;
  border-radius: 8px;
  background-color: #FFFFFF;
  margin-right: 18px;
  margin-left: 18px;
  text-align: center;
  line-height: 23px;
  font-size: 20px;
  color: #000000;
  border: 1px solid #3C96D4;
  font-size: 13px;
  font-family: Arial, Helvetica, sans-serif;
}

.Button:hover{
  cursor: pointer;
  background-color: #e7e7e7;
}

.keyBox {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: start;
}

.dropdown {
  position: relative;
  height: 25px;
  width: 130px;
}