.clientProfileContent {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  height: 100vh;
}

.ClientProfileScrollableArea {
  height: 100%;
  overflow: auto;
  padding-right: 15px;
}
  
.ClientProfileScrollableArea::-webkit-scrollbar {
  width: 9px;
}
.ClientProfileScrollableArea::-webkit-scrollbar-track {
  background-color: #f1f1f1;
} 
.ClientProfileScrollableArea::-webkit-scrollbar-thumb {
  background-color: #3C96D4;
  border-radius: 5px;
}


.BackBtn {
  margin-top: 44px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.BackBtn:hover {
  transform: scale(1.1);
}

.BackBtn img {
  width: 100%;
  height: 90%;
}

.ClientName, .SubTitle {
  font-family: 'Baloo Bhai', cursive;
  font-weight: bold;
  font-size: 24px;
  margin-top: 46px;
}

.ClientName {
  position: relative;
  display: inline-block;
  padding: 0;
  padding-right: 30px;
}

.ProjectTags {
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
}

.ProjectTags ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
}

.ProjectTags li {
  margin-right: 15px;
  margin-bottom: 10px;
}

.ProjectTags .Tag {
  background-color: #F37021;
  color: #FFFFFF;
  border-radius: 8px;
  font-weight: bold;
  font-size: 14px;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 108px;
  height: 25px;
  text-align: center;
  justify-content: center;
  display: flex; /* Add this to use flexbox */
  align-items: center;
}

.PlaceHolderBubble {
  max-width: 108px;
  height: 25px;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-inline: 15px;
}

.StatusBubble {
  border-radius: 8px;
  outline: solid 2px #F37021;
  outline-offset: -2px;
  background-color: #FFF;
  color: #F37021;
  border-radius: 8px;
  font-weight: bold;
  font-size: 14px;
  width: 108px;
  height: 25px;
  text-align: center;
  justify-content: center;
  display: flex; /* Add this to use flexbox */
  align-items: center;
}


.ActiveBubble {
  background-color: #0B8A00;
  color: #FFFFFF;
  border-radius: 8px;
  font-weight: bold;
  font-size: 14px;
  width: 108px;
  height: 25px;
  text-align: center;
  justify-content: center;
  display: flex; /* Add this to use flexbox */
  align-items: center;
}

.InvoiceBubble {
  background-color: #ffca28;
  color: #FFFFFF;
  border-radius: 8px;
  font-weight: bold;
  font-size: 14px;
  width: 108px;
  height: 25px;
  text-align: center;
  justify-content: center;
  display: flex; /* Add this to use flexbox */
  align-items: center;
}

/* Bubble Resizing */
.LogBookTable td.LogStatus.ActiveBubble
{
  max-width: 108px;
  /* margin-left: 15px; */
  margin-inline: 15px;
  /* padding-inline: 24px; */
}

.LogBookTable td.LogStatus.InvoiceBubble
{
  max-width: 108px;
  /* margin-left: 15px; */
  margin-inline: 15px;
  /* padding-inline: 24px; */
}

.folderIcon {
  padding: 4px 3px 3px 11px;
}

.pinIcon {
  position: absolute;
  transform: translateY(-50%);
  z-index: 10;
  margin-left: 5px;
  margin-top: 10px;
  cursor: pointer;
}

.editIcon {
  position: absolute;
  transform: translateY(-50%);
  z-index: 10;
}
.editIcon:hover {
  cursor: pointer;
}

.editIcon.Address {
  top: 50%;
  right: 6px;
}

.editIcon.ProjectInfo {
  top: 15px;
  right: 6px;
  z-index: 10;
}

.editIcon.ClientDetails {
  /* top: 15px; */
  /* right: 6px; */
  z-index: 10;
}

.ClientAddress {
  margin-top: -5px;
}

.ProjectTags {
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
}

.ProjectTags .LeftAlignment .ProjectFolderBtn.disabled {
  background-color: grey;
  cursor: not-allowed;
}

.ProjectTags ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
}

.ProjectTags li {
  margin-right: 15px;
  margin-bottom: 10px;
}

.ProjectTags .Tag {
  background-color: #F37021;
  color: #FFFFFF;
  border-radius: 8px;
  font-weight: bold;
  font-size: 14px;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 108px;
  height: 25px;
  text-align: center;
  justify-content: center;
  display: flex; /* Add this to use flexbox */
  align-items: center;
}

.LeftAlignment {
  margin-left: auto;
  display: flex;
  flex-direction: row;
}


.PlaceHolderBubble {
  max-width: 108px;
  height: 25px;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-inline: 15px;
}

.ProjectDetails {
  display: flex;
  gap: 24px; /* Adds a 24px gap between each child element */
}

.ProjectInformation {
  display: flex;
  /* flex-direction: row; */
  /* padding-left: 175px; */
  justify-content: space-between;
}

.ProjectStats, .ProjectStageInfo {
  box-sizing: border-box;
  position: relative;
  display: flex;
  
  /* flex: 1; */
  
  margin-top: 10px;
  padding-inline: 24px;
  padding-block: 10px;

  min-width: 504px;
  width: auto;
  height: 130px;

  background: #FFFFFF;
  border: 2px solid #ECEEF7;
  border-radius: 6px;

  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #949494;
}

.ProjectInvoicedContainer, .ProjectBtnsContainer {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.ProjectBtns {
  margin: 10px 15px 0px 18px;

  height: 102px;
  box-sizing: border-box;

  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #949494;
}

.HorBlock1, .HorBlock2, .HorBlock3 {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: auto;
}

.InfoText {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #343434;
  white-space: nowrap;

  margin-left: 5px;

  flex-grow: 1;
  text-align: left;

  width: auto;
}

.FieldContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-inline: 24px; */
  height: 50%;
  width: auto;
  white-space: nowrap;
}

.ProjectStats .HorBlock2 .FieldContainer {
  margin-left: 40px;
}

.ProjectStats .HorBlock3 .FieldContainer {
  margin-left: -25px;
}

.ProjectStageInfo .HorBlock3 .FieldContainer {
  margin-left: 15px;
}

.FieldTitle {
  width: auto;  /* Set this to the width of your widest title */
  text-align: right;
}


.HorBlock1 .JobFee {
  /* margin-top: 22px; */
  /* margin-left: 30px; */
  height: 50%;
  white-space: nowrap;
}

.HorBlock1 .JobType {
  /* margin-top: -10px; */
  /* margin-left: 24px; */
  height: 50%;
  white-space: nowrap;
}

.HorBlock2 .Drainage {
  /* margin-top: 22px; */
  /* margin-left: 44px; */
  height: 50%;
  white-space: nowrap;
}

.HorBlock2 .TempWorks {
  /* margin-top: -10px; */
  /* margin-left: 24px; */
  height: 50%;
  white-space: nowrap;
}

.HorBlock3 .pFB {
  /* text-align: center; */
  /* margin-right: 24px; */
  height: 50%;
  white-space: nowrap;
}

.HorBlock3 .FB {
  /* text-align: center; */
  /* margin-right: 23.5px; */
  height: 50%;
  white-space: nowrap;
}

.HorBlock1 .PhoneNo {
  /* text-align: center; */
  /* padding-left: 48px; */
  height: 50%;
  white-space: nowrap;
}

.HorBlock1 .Address {
  /* text-align: center; */
  /* margin-right: 30px; */
  height: 50%;
  white-space: nowrap;
}

.HorBlock1 .Email {
  height: 50%;
  white-space: nowrap;
}

.HorBlock3 .Stage {
  /* text-align: center; */
  /* padding-left: 30px; */
  height: 50%;
  white-space: nowrap;
}

.HorBlock3 .NextIssueDate {
  /* margin-top: -10px; */
  /* margin-left: 30px; */
  /* text-align: center; */
  /* padding-inline: 24px;  */
  height: 50%;
  white-space: nowrap;
}

.CommentsArea {
  display: flex;
  flex-direction: row;
}

.LongBtn {
  width: 133px;
  height: 25px;
  border-radius: 8px;
  color: white;
  text-align: center;
  font-family: 'PT Sans', sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 25px;
}

.ReqInv.LongBtn {
  background: #FFCA28; /* Yellow */
}

.ReqInv.LongBtn.disabled {
  background-color: grey;
  cursor: not-allowed;
}

.ReqInv.LongBtn:hover {
  cursor: pointer;
}

.ReqInv.LongBtn.disabled:hover {
  cursor: not-allowed;
}


.AddWorks.LongBtn, .Triage.LongBtn {
  background: #3C96D4;
}

.AddWorks.LongBtn:hover, .Triage.LongBtn:hover {
  cursor: pointer;
  background: #2a6d9e;
}

/* LOG TABLE SECTION */

.LogBook {
  display: flex;
  flex-direction: column;
  margin-left: -4px;
  margin-top: 24px;

  background: #FFFFFF;
  border: 2px solid #ECEEF7;
  border-radius: 6px;
}

.LogBookTable {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin-block: 15px;
}

.LogBookTable table {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-collapse: collapse;
  height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Customize the scrollbar Sidebar */
.LogBookTable table::-webkit-scrollbar {
  width: 8px; /* Adjust the width of the scrollbar */
}

/* Customize the scrollbar track */
.LogBookTable table::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Set the background color of the track */
}

/* Customize the scrollbar thumb */
.LogBookTable table::-webkit-scrollbar-thumb {
  background-color: #3C96D4; /* Set the background color of the thumb */
  border-radius: 5px; /* Add border-radius to make the thumb rounder */
}

/* Customize the scrollbar thumb on hover */
.LogBookTable table::-webkit-scrollbar-thumb:hover {
  background-color: #3C96D4; /* Set the background color of the thumb on hover */
  cursor: pointer; /* Change the mouse cursor to pointer on hover */
}

.LogRow {
  align-items: center;
  justify-content: left;
  display: flex;

  border: 2px solid transparent;
  border-radius: 6px; /* Adjust this value to change the roundness of the edges */
  transition: border-color 0.3s ease; /* This makes the color change smoothly */
}

.LogRow:hover {
  border-color: #ECEEF7; /* Change this to the blue color you want */
  background-color: transparent;
}

.LogBookTable td {
  flex: 1;
  flex-grow: 1;
}


/* FORMATTING ROWS AND COLUMNS */
.LogBookTable td.LogActivity {
  font-family: 'PT Sans';
  font-style: bold;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #292929;

  margin-right: 15px;
}

td.LogActivity {
  text-align: left;
  margin-left: 15px;
  /* min-width: 125px; */
}

.LogBookTable td.LogActivity.wide {
  max-width: 15%;
  /* min-width: 0px; */
  /* margin-right: 190px; */
}

.LogBookTable td.LogStatus.wide {
  min-width: 135px;
}


/* Media query for screen sizes between 768px and 1023px */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .LogBookTable td.LogComments.wide {
    min-width: 35%; /* Increased width for screen sizes in between */
  }

  .LogBookTable td.LogActivity.wide {
    max-width: 15%;
  }

  .LogBookTable td.LogDescription {
    margin-left: 7.5%; /* Adjust this value as needed for larger screens */
  }
}

/* Media query for screen sizes between 1024px and 1439px */
@media screen and (min-width: 1024px) and (max-width: 1536px) {
  .LogBookTable td.LogComments.wide {
    min-width: 40%; /* Increased width for screen sizes in between */
  }

  .LogBookTable td.LogActivity.wide {
    max-width: 14%;
  }

  .LogBookTable td.LogDescription {
    margin-left: 5.75%;
    max-width: 25%; /* Adjust this value as needed for larger screens */
  }
}

/* Media query for screen sizes between 1440px and 1919px */
@media screen and (min-width: 1537px) and (max-width: 1745.5px) {
  .LogBookTable td.LogDescription {
    margin-left: 7.75%;
    max-width: 23%; /* Adjust this value as needed for larger screens */
  }
}

/* Media query for screen sizes between 1440px and 1919px */
@media screen and (min-width: 1746px) and (max-width: 1919px) {
  .LogBookTable td.LogDescription {
    max-width: 15%; /* Adjust this value as needed for larger screens */
  }
}

/* Media query for even bigger screen sizes */
@media screen and (width: 1920px) {
  .LogBookTable td.LogComments.wide {
    min-width: 50%; /* Further increased width for even bigger screen sizes */
  }

  .LogBookTable td.LogActivity.wide {
    max-width: 11%;
  }

  .LogBookTable td.LogDescription {
    margin-left: 5.75%;
    max-width: 20%; /* Adjust this value as needed for larger screens */
  }
}

/* Media query for screen sizes between 1921px and 2559px */
@media screen and (min-width: 1921px) and (max-width: 2134px) {
  .LogBookTable td.LogComments.wide {
    min-width: 60%; /* Increased width for screen sizes in between */
  }

  .LogBookTable td.LogActivity.wide {
    max-width: 10%;
  }

  .LogBookTable td.LogDescription {
    margin-left: 1.25%;
    max-width: 25%; /* Adjust this value as needed for larger screens */
  }
}

/* Media query for screen sizes between 2400px and 2559px */
@media screen and (min-width: 2400px) and (max-width: 2559px) {
  .LogBookTable td.LogComments.wide {
    min-width: 60%; /* Increased width for screen sizes in between */
  }

  .LogBookTable td.LogActivity.wide {
    max-width: 10%;
  }

  .LogBookTable td.LogDescription {
    margin-left: 7.25%;
    max-width: 30%; /* Adjust this value as needed for larger screens */
  }
}

/* Media query for screen sizes between 2560px and 2880px */
@media screen and (min-width: 2560px) and (max-width: 2879px) {
  .LogBookTable td.LogComments.wide {
    min-width: 70%; /* Increased width for screen sizes in between */
  }

  .LogBookTable td.LogActivity.wide {
    max-width: 9%;
  }

  .LogBookTable td.LogDescription {
    max-width: 30%; /* Adjust this value as needed for larger screens */
  }
}

/* Media query for screen sizes between 2560px and 3839px */
@media screen and (min-width: 2880px) and (max-width: 3839px) {
  .LogBookTable td.LogComments.wide {
    min-width: 70%; /* Increased width for screen sizes in between */
    max-width: 70%;
  }

  .LogBookTable td.LogActivity.wide {
    max-width: 9%;
  }

  .LogBookTable td.LogDescription {
    margin-left: 2.25%;
    max-width: 30%; /* Adjust this value as needed for larger screens */
  }
}

/* Media query for screen sizes of 3840px and above */
@media screen and (min-width: 3840px) {
  .LogBookTable td.LogComments.wide {
    min-width: 78%; /* Further increased width for even bigger screen sizes */
  }

  .LogBookTable td.LogActivity.wide {
    max-width: 8%;
  }

  .LogBookTable td.LogDescription {
    /* margin-left: .25%; */
    max-width: 25%; /* Adjust this value as needed for larger screens */
  }
}


.LogBookTable td.LogDescription {
  /* max-width: 50%; */
  /* min-width: 20%; */
  /* color: #949494;
  margin-left: 115px;
  padding-inline: 15px;
  text-align: left; */
  /* min-width: 330px; */

  /* Remove any fixed margin or padding */
  padding-inline: 15px;

  /* Use relative units for positioning and sizing */
   /* Adjust this value as needed */
  /* max-width: 85%; */
  color: #949494;
  text-align: left;
}

.LogBookTable td.LogComments,
.LogBookTable td.LogDescription {
  /* padding-top: 10px; */
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.01em;
}

.LogBookTable td.LogComments {
  color: #000000;
  flex-grow: 1;
  /* margin-left: 20px; */
}

.LogBookTable td.LogComments.wide {
  padding-inline: 15px;
}

.LogBookTable td.LogActivity.wide,
.LogBookTable td.LogStatus.wide {
  padding: 0;
  margin: 0;
}

.CommentsSection {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  flex-grow: 1;
  min-width: 0;

  /* margin-top: 24px; */
  margin-left: -4px;
  height: 108px;

  background: #FFFFFF;
  border: 2px solid #ECEEF7;
  border-radius: 6px;

  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.01em;
}

.CommentsSection p {
  margin-right: 20px;
}

.CommentsSection .Status {
  display: flex;
  align-items: center;
  color: #949494;

  line-height: 18px;
  margin-top: -5px;
  margin-left: 30px;
}

.Dropdown {
  position: relative;
  height: 25px;
  width: 130px;
}

.CommentsSection .Status .Dropdown {
  margin-left: 15px;
  color: #000000;
}

.CommentsSection .Activity {
  display: flex;
  align-items: center;
  color: #949494;

  line-height: 18px;
  margin-top: -5px;
  margin-left: 10px;
}

.CommentsSection .Activity .Dropdown {
  margin-left: 15px;
  color: #000000;
  width: 140px;
}

.Comment {
  display: flex;
  align-items: center;
  color: #949494;
  width: 100%;

  line-height: 18px;
  margin-top: -5px;
  margin-left: 10px;
}

.CommentInput {
  min-width: 507px;
  height: 50px;
  font-family: 'PT Sans';
  background: #FFFFFF;
  border: 1px solid #3C96D4;
  border-radius: 8px;
  overflow-wrap: break-word;
  resize: none;
  flex-grow: 1;

  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;

  margin-top: 32px;
  margin-left: 20px;
}

.CommentInput::placeholder {
  color: #D9D9D9;
}

.CommentInput:focus {
  border: 1px solid #D9D9D9;
}

.CommentInput.invalid {
  border: 2px solid red;
  animation: pulsate 0.5s ease-in-out;
}

@keyframes pulsate {
  0% {
    border-color: red;
  }
  50% {
    border-color: transparent;
  }
  100% {
    border-color: red;
  }
}

.CommentsSection .Post {
  display: flex;
  align-items: center;
  flex-grow: 1;

  margin-top: 0px;
  min-width: 50px;
  /* margin-left: 470px; */
}

.CommentsSection .Post .PostBtn {
  box-sizing: border-box;

  width: 125px;
  height: 25px;
  background: #3C96D4;
  border-radius: 8px;
  border: none;

  /* font-family: 'PT Sans', sans-serif; */
  font-weight: bold;
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  color: #FFFFFF;
}

.CommentsSection .Post .PostBtn:hover {
  background-color: #2a6d9e;
  cursor: pointer;
}

.CommentsSection .Post .PostBtn.disabled {
  background-color: grey;
  cursor: not-allowed;
}

.CommentsSection .Post .PostBtn.disabled:hover {
  cursor: not-allowed;
}
.ProjectFolderBtn {
  width: 40px;
  height: 25px;
  background: #3C96D4;
  border-radius: 8px;
  border: none;
} 

.ProjectFolderBtn:hover {
  cursor: pointer;
  background-color: #2a6d9e;
}

.ProjectFolderBtn ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
}

/* .CommentsSection .Post .PostBtn:active {
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
} */

/* Customize the scrollbar Sidebar */
.CommentInput::-webkit-scrollbar {
  width: 8px; /* Adjust the width of the scrollbar */
}

/* Customize the scrollbar track */
.CommentInput::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Set the background color of the track */
}

/* Customize the scrollbar thumb */
.CommentInput::-webkit-scrollbar-thumb {
  background-color: #3C96D4; /* Set the background color of the thumb */
  border-radius: 5px; /* Add border-radius to make the thumb rounder */
}

/* Customize the scrollbar thumb on hover */
.CommentInput::-webkit-scrollbar-thumb:hover {
  background-color: #2a6d9e; /* Set the background color of the thumb on hover */
  cursor: pointer; /* Change the mouse cursor to pointer on hover */
}

/* New CSS for Status Dropdown Box */

.CommentsSection .Status .Dropdown .dropdownOptions {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  white-space: nowrap;
  background-color: #fff;
  width: 88px;
}

.CommentsSection .Status .Dropdown .dropdownSelected {
  width: 80px;
}

.CommentsSection .Status .Dropdown .dropdownOptions {
  width: 72px;
}

/* New CSS for Activity Dropdown Box */

.CommentsSection .Activity .Dropdown .dropdownOptions {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  white-space: nowrap;
  background-color: #fff;
  width: 148px;
}

.CommentsArea .CommentsSection .Activity .Dropdown .dropdownSelected {
  width: 140px;
}

.CommentsSection .Activity .Dropdown .dropdownOptions {
  width: 132px;
}

.ProjectProfileOverlay {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ProjectProfileResponseBox {
  background-color: #ffffff;
  min-width: 160px;
  max-width: 500px;
  min-height: 140px;
  max-height: 300px;
  border-radius: 20px;
  border: solid 2px #3C96D4;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.ProjectProfileButton {
  justify-content: center;
  align-content: center;
  text-align: center;
  
  min-width: 80px;
  height: 25px;
  border-radius: 8px;
  color: white;
  background-color: #3C96D4;
  font-family: 'PT Sans', sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 25px;

  cursor: pointer;
  margin-bottom: 20px;
  margin-right: 10px;
  /* padding: 0 10px; */
}

.ProjectProfileButton:active {
  background-color: #ccc;
}

.ProjectProfileButton:hover {
  background-color: #165b8a;
}

.SwitchContainer {
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust the space between labels and switch as needed */
  margin-bottom: 10px;
}

.Label {
  cursor: pointer;
  color: #666; /* Non-active color */
}

.Label.active {
  color: #000; /* Active color */
  font-weight: bold;
}

.LastUpdated {
  font-family: 'PT Sans',sans-serif;
  color: #949494;
  font-size: 12px;
  font-weight: 700;
}
