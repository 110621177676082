.infoSector {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 12px;
  border-radius: 6px;
}

.infoBox {
  border: solid 2px #ECEEF6;
  padding: 16px 28px 16px 22px;
  background-color: #ffffff;
  margin: 0 12.5px 0 12.5px;
  position: relative;
  border-radius: 8px;
}

.thinList {
  padding: 0px;
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 22px;
  margin: 0px;
}

.dataRow {
  display: contents;
}

.dataName {
  font-family: 'PT Sans', sans-serif;
  font-size: 12px;
  color: #949494;
  text-align: right;
  height: 18.18px;
  margin: 8px 0 4px 0;
}

.data,
.noData {
  font-family: 'PT Sans', sans-serif;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin: 6px 0 6px 0;
  text-align: left;
}

.noData {
  color: #4a60a8;
}

.editIcon {
  position: absolute;
  /* This allows the img to be positioned anywhere within the div */
  top: 6px;
  /* Aligns the img with the top of the div */
  right: 6px;
  /* Aligns the img with the right of the div */
  z-index: 10;
  /* This ensures that the img is displayed above all other content in the div. Increase this value if the img is still behind other elements */
}

.editIcon:hover {
  cursor: pointer;
}