.projectContent {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  height: calc(100vh - 120px);
  overflow-y: auto;
  overflow-x: hidden;
}

.tdContent {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  margin: 0px;
}

.Projecttitle {
  font-family: 'Baloo Bhai', cursive;
  font-weight: bold;
  font-size: 24px;
  margin-top: 27px;
  margin-bottom: 40px;
}

.ProjectListBox table {
  width: 100%;
  max-width: 100%; /* Add this line */
  border-collapse: collapse;
  table-layout: initial;
}

.ProjectListBox {
  background-color: #FFFFFF;
  border: 2px solid #ECEEF6;
  max-width: 200vw;
  border-radius: 6px;
  position: relative; /* Add this to create a new positioning context */
  overflow-y: scroll;
  overflow-x: hidden;
}

/* Customize the scrollbar Sidebar */
.ProjectListBox::-webkit-scrollbar {
  width: 7px; /* Adjust the width of the scrollbar */
}

/* Customize the scrollbar track */
.ProjectListBox::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Set the background color of the track */
  border: 15px;
}

/* Customize the scrollbar thumb */
.ProjectListBox::-webkit-scrollbar-thumb {
  background-color: #3C96D4; /* Set the background color of the thumb */
  border-radius: 10px; /* Add border-radius to make the thumb rounder */
}

/* Customize the scrollbar thumb on hover */
.ProjectListBox::-webkit-scrollbar-thumb:hover {
  background-color: #3C96D4; /* Set the background color of the thumb on hover */
  cursor: pointer; /* Change the mouse cursor to pointer on hover */
}

.ProjectListBox th {
  font-weight: normal;
  font-size: 12px; /* Set the font size of the column names */
  color: #949494; /* Set the color of the column names */
  padding: 15px;
  text-align: left; /* Center the values in the columns */
  cursor: pointer;
  /* z-index: 1; */
  /* width: 5%; Add this to fix the width of the columns */
  white-space: nowrap;
  position: relative;
}

.ProjectListBox thead th{
  position: sticky; /* make the table heads sticky */
  top: 0px; /* table head will be placed from the top of the table and sticks to it */
  background: #ffffff;
  overflow: hidden;
  z-index: 2;
  
}

.ProjectPagination {
  padding-bottom: 30px;
}

.ProjectListBox td {
  font-size: 10px;
  padding: 8px;
  padding-left: 15px;
  font-family: 'PT Sans', sans-serif;
  cursor: pointer;
  white-space: normal;
  justify-content: center;
  align-items: center;
}
.skeleton {
  background: #ccc;
  width: 100%;
  height: 20px;
  animation: loading 0.5s infinite;
  border-radius: 5px;
}

@keyframes loading {
  0% {
    background: #ccc;
  }
  50% {
    background: #ddd;
  }
  100% {
    background: #ccc;
  }
}

.thContent {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  text-align: center;
}

.ProjectListBox td:nth-child(2),
.ProjectListBox th:nth-child(2),
.ProjectListBox td:nth-child(3),
.ProjectListBox th:nth-child(3),
.ProjectListBox td:nth-child(5),
.ProjectListBox th:nth-child(5)
{
  text-align: left;
}

.ProjectListBox td,
.ProjectListBox th {
  text-align: center;
  border-bottom: 1px solid #ECEEF6;
}

.td-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative; /* Change from absolute to relative */
  width: 100%;
}

.ProjectListBox tr:last-child td {
  border-bottom: none;
}

.StatusCurrent {
  color: #0B8A00;
}
  
.StatusLeaver {
  color: #D32030;
}

.StatusDormant {
  color: #5F5F5F;
}

.ProjectTableFilters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 10px);
  margin-bottom: 20px;
}

.ProjectTeamFilter,
.ProjectStatusFilter {
  /* display: flex; */
  align-items: center;
  z-index: 9998;
}

.ProjectStatusFilter {
  height: 25px;
  width: 130px;
  margin-left: 25px;
  display: flex;
}

.ProjectTeamFilter {
  display: flex;
  height: 25px;
  width: 120px;
}

.FilterLabel {
  margin-right: 10px;
  font-weight: normal;
  color: #949494; /* Set the color of the column names */
}

.ProjectListSearch {
  margin-left: auto;
}
  
.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  font-weight: bold;
  font-size: 12px; /* Set the font size of the column names */
  color: #949494; /* Set the color of the column names */
}
  
.pagination-info {
  text-align: center;
}
  
.pagination-main {
  display: flex;
  align-items: center;
}

.pagination-controls button {
  width: 25px;
  height: 25px;
  left: 358px;
  top: 543px;
  background: #FFFFFF;
  border: 1px solid #3C96D4;
  border-radius: 8px;
  cursor: pointer;
}
  
.pagination-right-control button {
  margin-left: 10px;
}

.pagination-left-control button {
  margin-right: 10px;
}

.pagination-controls button:disabled {
  background: #e0e0e0;
  border: 1px solid #D32030;
  border-radius: 8px;
  cursor: not-allowed;
}

.resizerBoxcolor {
  width: 1px;
  height: 100%;
  background-color: #dfdfdf;
}
  
.resizer {
  display: inline-block;
  background: #ffffff;
  padding: 11px;
  width: 2px;
  margin-top: -11px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  cursor: col-resize;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); */
  /* border-top-left-radius: 5px; */
  /* border-bottom-left-radius: 5px; */
  /* transition: background-color 0.2s ease-in-out; */
}
  
.resizer:hover,
.resizer.clicked {
  background-color: #ffffff;
}

.NoProjects {
  font-family: 'Baloo Bhai', cursive;
  font-weight: bold;
  font-style: normal;
  font-size: 12px;
  line-height: 19px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: #D9D9D9;
  height: 36px; /* adjust the height as needed */
}

button.disabled {
  background-color: lightgray;
  cursor: not-allowed;
}

.ProjectSearch.disabled,
.search-button.disabled {
  background-color: lightgray;
  cursor: not-allowed;
}

.search-button {
  background: none;
  border: none;
  padding: 0;
  margin-left: -30px; /* Adjust this value to align the SVG icon with the input field */
  width: 24px; /* Set the width to match the original image width */
  height: 24px; /* Set the height to match the original image height */
  background-image: url('../../../assets/Search.svg');
  background-repeat: no-repeat;
  background-position: 4px 6px; /* Adjust the second value to shift the image down */
}

.search-button:hover {
  cursor: pointer;
}

.search-button.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}