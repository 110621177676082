.app-container {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 60px 1fr; /* assuming your top navigation bar has a height of 60px */
  height: 100vh;
}

.main-content {
  width: 100vw;
  height: calc(100vh - 60px); /* assuming your top navigation bar has a height of 60px */
}

.MomentLogo {
  position: fixed; /* or 'absolute' depending on your needs */
  top: 14px;
  right: 28px;
  z-index: 500; /* high z-index to be above other elements */
  font-size: 36px;
  font-family: 'Baloo Bhai', cursive;
  font-weight: bold;
  color: #3C96D4;
}

.SandboxLogo {
  position: fixed; /* or 'absolute' depending on your needs */
  top: 14px;
  right: 28px;
  z-index: 500; /* high z-index to be above other elements */
  font-size: 36px;
  font-family: 'Baloo Bhai', cursive;
  font-weight: bold;
  color: #E19132;
}

body {
  margin: 0;
  padding: 0;
  background: #EAF2F9;
  font-family: 'PT Sans', sans-serif;
}
