.ProfileMainContent {
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 22px;
  margin-right: 2px;
  height: calc(100vh - 80px); /* Adjust the value based on the heights of the top navigation bar and any other elements that might affect the height */
  overflow: auto;
}

.ProfileMainContent::-webkit-scrollbar {
  width: 9px;
}

.ProfileMainContent::-webkit-scrollbar-track {
  background-color: #f1f1f1;
} 

.ProfileMainContent::-webkit-scrollbar-thumb {
  background-color: #3C96D4;
  border-radius: 5px;
}

.title {
  font-family: 'Baloo Bhai', cursive;
  font-weight: bold;
  font-size: 24px;
  margin-top: 27px;
}

.title2 {
  font-family: 'Baloo Bhai', cursive;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 28px;
}

.title3 {
  font-family: 'Baloo Bhai', cursive;
  font-weight: bold;
  font-size: 24px;
  color: #D9D9D9;
}

.holidayManagment {
  margin-bottom: 28px;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  font-family: 'PT Sans', sans-serif;
}

.daysLeft {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.holidayDays {
  display: flex;
  background-color: #FFFFFF;
  border: 2px solid #ECEEF6;
  max-width: 250px;
  min-width: 140px;
  height: 97px;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 13px;
  justify-content: center;
  align-items: center;
}

.studyDays {
  display: flex;
  background-color: #FFFFFF;
  border: 2px solid #ECEEF6;
  max-width: 250px;
  min-width: 140px;
  height: 97px;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 13px;
  margin-left: 20px;
  align-items: center;
  justify-content: center;
}

.RequestedDays {
  display: flex;
  background-color: #FFFFFF;
  border: 2px solid #ECEEF6;
  max-width: 250px;
  min-width: 100px;
  height: 40px;
  padding-right: 10px;
  padding-left: 15px;
  border-radius: 13px;
  margin-left: 7px;
  margin-right: 7px;
  align-items: center;
  justify-content: left;
}

.BookedAndAllowanceDays {
  display: flex;
  flex-direction: column;
}

.holidayDaysNum {
  font-family: 'Baloo Bhai', cursive;
  font-weight: bold;
  font-size: 64px;
  margin-right: 15px;
  font-family: 'PT Sans', sans-serif;
  flex-grow: 1;
  text-align: center;
}

.studyDaysNum {
  text-align: center;
  font-family: 'Baloo Bhai', cursive;
  font-weight: bold;
  font-size: 64px;
  min-width: 35px;
  margin-right: 8px;
  font-family: 'PT Sans', sans-serif;
  flex-grow: 1;
}

.holidayDaysText {
  word-wrap: break-word;
  font-family: 'Baloo Bhai', cursive;
  font-size: 20px;
}

.RequestedDaysText {
  word-wrap: break-word;
  font-family: 'Baloo Bhai', cursive;
  font-size: 20px;
}

.studyDaysText {
  word-wrap: break-word;
  font-family: 'Baloo Bhai', cursive;
  font-size: 20px;
}

.bookedLeaveInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bookedLeaveBox {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  border: 2px solid #ECEEF6;
  width: 750px;
  min-height: 160px; /* Adjust this value to accommodate up to 4 rows without showing the scrollbar */
  border-radius: 6px;
  position: relative; /* Add this to create a new positioning context */
  overflow-y: auto; /* Add this to enable a scrollbar when the content exceeds the container's height */
}

.bookedLeaveBox table {
  width: 100%;
  height: 100%; /* Set the table's height to 100% */
  border-collapse: collapse;
  font-family: 'PT Sans', sans-serif;
}

.bookedLeaveBox th {
  font-weight: normal;
  font-size: 12px; /* Set the font size of the column names */
  color: #949494; /* Set the color of the column names */
  padding: 8px;
  text-align: center; /* Center the values in the columns */
  border-bottom: 1px solid #ECEEF6;
}

.bookedLeaveBox td {
  font-size: 14px;
}

.bookedLeaveBox th,
.bookedLeaveBox td {
  padding: 8px;
  text-align: center; /* Center the values in the columns */
}

.bookedLeaveBox thead th {
  border-bottom: 1px solid #ECEEF6; /* Add a 1px line between the column names and the rows */
}

.bookedLeaveBox tr:last-child td {
  border-bottom: none;
}

.statusPending {
  background-color: #F37021;
  color: #FFFFFF;
  border-radius: 8px;
  font-weight: bold;
  font-size: 14px;
  width: 92px;
  height: 25px;
  text-align: center;
  justify-content: center;
  display: flex; /* Add this to use flexbox */
  align-items: center;
}

.statusApproved {
  background-color: #0B8A00;
  color: #FFFFFF;
  border-radius: 8px;
  font-weight: bold;
  font-size: 14px;
  width: 92px;
  height: 25px;
  text-align: center;
  justify-content: center;
  display: flex; /* Add this to use flexbox */
  align-items: center;
}

.cancelButton {
  color: #3C96D4;
  border-radius: 8px;
  font-weight: bold;
  font-size: 14px;
  width: 92px;
  height: 25px;
  text-align: center;
  justify-content: center;
  display: flex; /* Add this to use flexbox */
  align-items: center;
  border: 2px solid#3C96D4;
  cursor: pointer;
}

.cancelButton:hover {
  background-color: #f3f3f3; /* Change the background color */
}

.bookingLeave {
  margin-left: 24px;
  margin-top: 28px;
  background-color: #FFFFFF;
  border: 2px solid #ECEEF6;
  width: 333px;
  height: 283px;
  border-radius: 6px;
}

.bookedLeaveBox::-webkit-scrollbar {
  width: 7px;
}

.bookedLeaveBox::-webkit-scrollbar-track {
  background-color: #ECEEF6;
  border-radius: 10px;
}

.bookedLeaveBox::-webkit-scrollbar-thumb {
  background-color: #3C96D4;
  border-radius: 10px;
}

.bookedLeaveBox::-webkit-scrollbar-thumb:hover {
  background-color: #7A7A7A;
}

.bookingLeaveItem {
  margin-bottom: 10px;
}

.bookingLeaveLabel {
    display: block;
    font-size: 12px;
    margin-bottom: 11px;
    color: #949494;
}

.bookingLeaveInteraction {
    margin: 23px;
    display: flex;
    flex-direction: column;
}

.selectsSection {
    width: 100%;
    display: flex;
    margin-bottom: 19px;
    height: 51px;
}

.typeOfLeave {
  margin-right: 20px;
  width: 130px;
}

.leaveLength {
  width: 130px;
}

.dropdowns {
  position: relative;
  display: inline-block;
  height: 25px;
  width: 130px;
}

.bookingLeaveSelect {
  font-size: 13px;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: bold;
  border-radius: 5px;
  border: 1px solid #3C96D4;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  background-color: #fff;
  color: #333;
  width: 130px;
  height: 25px;
  box-sizing: border-box;
  cursor: pointer;
  transition: border-color 0.3s;
}

.bookingLeaveSelect:focus {
  border-color: #007bff;
}

.bookingLeaveSelect::-ms-expand {
  display: none;
}

.bookingLeaveSelect optgroup {
  font-weight: bold;
}

.bookingLeaveSelect optgroup:hover {
  background-color: #000000;
  font-weight: bold;
}

.bookingLeaveDate {
  padding-left: 8px;
  padding-right: 8px;
  font-size: 13px;
  font-weight: bold;
  width: 130px;
  height: 25px;
  resize: none;
  border-radius: 9px;
  border: 1px solid #3C96D4;
}

.bookingLeaveComment {
  padding-left: 4px;
  padding-right: 4px;
  font-size: 12px;
  width: 276px;
  height: 40px;
  resize: none;
  border-radius: 9px;
  border: 1px solid #3C96D4;
  font-family: 'PT Sans';
  -webkit-transition: 0.5s;
  transition: 0.2s;
  outline: #D9D9D9;
  color: black;
}

.nonBookingLeaveComment{
  padding-left: 4px;
  padding-right: 4px;
  font-size: 12px;
  width: 276px;
  height: 40px;
  resize: none;
  border-radius: 9px;
  border: 1px solid #3C96D4;
  font-family: 'PT Sans';
  -webkit-transition: 0.5s;
  transition: 0.2s;
  outline: #D9D9D9;
  color: #D9D9D9;
}

.bookingLeaveComment:focus {
  border: 1px solid #c4c4c4;
}

.nonBookingLeaveComment::placeholder {
  color: #D9D9D9 !important;
}

.bookingLeaveSubmit {
  justify-content: center;
  align-content: center;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  background-color: #3C96D4;
  border: none;
  border-radius: 6px;
  width: 83px;
  height: 25px;
  line-height: 25px;
  cursor: pointer;
}

.submitdiv {
    justify-content: center;
    align-content: center;
    text-align: center;
    margin: none;
    padding: none;
}

.bookingLeaveSubmit:hover {
    background-color: #2a6d9e;
}

.bookingLeaveRow {
    margin-bottom: 20px;
    justify-content: center;
    align-content: center;
    text-align: center;
    height: 51px;
}

.bookingLeaveRowComment {
  height: 47px;
  width: 282px;
  margin-bottom: 19px;
}

.bookedLeaveBox thead th {
  position: sticky; /* make the table heads sticky */
  top: 0px; /* table head will be placed from the top of the table and sticks to it */
  background: #ffffff;
}

:root {
  --rdr-color-primary: #3C96D4;
}

.backgroundOfDatePicker {
  display: flex;
  justify-content: center; 
  align-items: center;
  position: fixed;
  inset: 0;
  background: rgba(0,0,0,0.5);
  z-index: 1000000000;
}

.rdrCalendarWrapper {
  border-radius: 10px;
  border: 1px solid #3C96D4;
}

.rdrDefinedRangesWrapper {
  display: none;
}

.rdrDateDisplayWrapper {
  border-radius: 10px 10px 0px 0px;
}

:root:root {
  --rdr-color-primary: #3C96D4;
}

.rdrDateDisplayItemActive {
  border-color: #3C96D4;
}

.inputDateLeaveProfile {
  height: 15px;
  max-width: 151px;
  min-width: 110px;
  text-align: center;
  padding: 0px;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: #fff;
  border: 1px solid #3C96D4;
  border-radius: 8px;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
}

.rightColumnHolidayBookingSection {
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftColumnHolidayBookingSection{
  display: flex;
  justify-content: center;
  align-items: center;
}

.skeleton {
  background: #ccc;
  width: 100%;
  height: 20px;
  animation: loading 0.5s infinite;
  border-radius: 5px;
}

@keyframes loading {
  0% {
    background: #ccc;
  }
  50% {
    background: #ddd;
  }
  100% {
    background: #ccc;
  }
}

.ProfileHolidayOverlay {
  background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent background */
  position: fixed; /* Cover the entire screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000; /* Ensure it's above everything else */
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
}

.ProfileHolidayResponseBox {
  background-color: #ffffff;
  min-width: 160px;
  max-width: 500px;
  min-height: 140px;
  max-height: 300px;
  border-radius: 20px;
  border: solid 2px #3C96D4;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.ProfileHolidayButton {
  justify-content: center;
  align-content: center;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  background-color: #3C96D4;
  border: none;
  border-radius: 6px;
  width: 37px;
  height: 28px;
  cursor: pointer;
  margin-bottom: 20px;
}

.ProfileHolidayButton:active {
  background-color: #ccc; /* Change the background color */
}

.ProfileHolidayButton:hover {
  background-color: #165b8a; /* Change the background color */
}