.Card {
    border-radius: 6px;
    margin-bottom: 10px;
    border: 2px solid #ECEEF6;
    background: #fff;
    box-shadow: none !important;

    max-width: 240px;
    max-height: auto;
    flex-shrink: 0;
}

.CardContent {
    padding: 12px !important;
}

.cardTitle {
    color: #000;
    font-family: "PT Sans" !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    letter-spacing: 0.12px !important;
    margin-bottom: 6px !important;
}

.cardSubtext {
    font-family: "PT Sans" !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    letter-spacing: 0.12px !important;
    margin-bottom: 6px !important;
}

.client {
    color: #3C96D4 !important;
    margin-left: 24px !important;
}

.deadline {
    margin-left: -4px !important;
}

.dateOverdue {
    color: #D32030 !important;
}

.dateDueIn7 {
    color: #0B8A00 !important;
}

.dateDueAfter7 {
    color: #000 !important;
}

.cardButtons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    justify-content: center;
    align-items: center;
    text-align: center;
}

.FolderIcon {
    filter: brightness(0) saturate(100%) invert(52%) sepia(45%) saturate(650%) hue-rotate(161deg) brightness(94%) contrast(94%);
}

.AssigneeDropdown {

    background: #FFF;
}