.Comment {
  padding: 25px;
  font-size: 13px;
  font-weight: bold;
  width: calc(100% - 50px);
  height: calc(100% - 50px);
  resize: none;
  border-radius: 9px;
  border: 1px solid #3C96D4;
  font-family: 'PT Sans';
  -webkit-transition: 0.5s;
  transition: 0.2s;
  outline: #D9D9D9;
}
.Comment:focus {
  border: 1px solid #c4c4c4;
}
.Comment::placeholder {
  color: #242424;
}

@keyframes pulsate {
  0% {
    border-color: red;
  }
  50% {
    border-color: transparent;
  }
  100% {
    border-color: red;
  }
}


.invalidInput {
  /* copy all styles from .Comment, and add the animation */
  animation: pulsate 0.5s ease-in-out;
  animation-iteration-count: 1;
  border: 1px solid #3C96D4;
}
