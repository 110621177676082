.ProjectSearch {
    width: 100%;
    height: 25px;
    left: 1115px;
    top: 238px;

    background: rgba(168, 168, 174, 0.15);
    border-radius: 8px;

    border: none;

    /* background-image: url('../../../../assets/Search.svg'); */
    /* background-repeat: no-repeat; */
    /* background-position: 10px center; */

    /* Add the following line to move the inputted text to the right */
    text-indent: 10px; /* Adjust this value to move the inputted text to the desired position OG: 30px*/
}

.ProjectSearch.disabled,
.searchButton.disabled {
  background-color: lightgray;
  cursor: not-allowed;
}

.searchButton {
  background: none;
  border: none;
  padding: 0;
  margin-left: -30px; /* Adjust this value to align the SVG icon with the input field */
  width: 24px; /* Set the width to match the original image width */
  height: 24px; /* Set the height to match the original image height */

  background-image: url('../../assets/Search.svg');
  background-repeat: no-repeat;
  background-position: 4px 5px; /* Adjust the second value to shift the image down */

}

.searchButton:hover {
  cursor: pointer;
}
  
/* Additional styles for disabled state */
.searchButton.disabled, .disable-click {
  cursor: not-allowed;
  opacity: 0.5;
}

.searchIconPlaceholder {
  display: inline-block;
  margin-top: -10.5px;
  width: 24px;
  height: 24px;
}
