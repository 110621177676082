.titles {
    font-family: 'Baloo Bhai', cursive;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }

  
  .titles:hover {
    cursor: pointer;
  }

  .arrow {
    width: 2px;
    height: 2px;
    content: "";
    right: 12px;
    border: solid;
    border-width: 0 2px 2px 0;
    border-radius: 1px;
    padding: 2px;
  }

  .positionArrow {
    margin-right: 11px;
  }