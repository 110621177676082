.select {
  height: 25px;
  width: calc(100% - 8px);
  padding-left: 8px !important;
  padding-top: 8px !important;
  padding-bottom: 6px !important;
  background-color: #fff !important;
  border: 1px solid #3c96d4 !important;
  border-radius: 8px !important;
  cursor: pointer;
  font-size: 13px !important;
  font-weight: bold !important;
  font-family: "PT Sans", sans-serif;
}

.selectItem {
  font-size: 13px !important;
  font-family: "PT Sans", sans-serif !important;
  font-weight: bold !important;
  box-shadow: "0px 2px 4px rgba(0, 0, 0, 0.1)";
  border-radius: "8px 8px 8px 8px";
  border: "1px solid #3C96D4";
  background-color: "#fff";
  padding: 2px;
  padding-left: 16px;
  margin: 0;
}

.width-lg {
    width: 400px !important;
}
.width-md {
    width: 200px !important;
}