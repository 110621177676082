.btn {
    background: #3C96D4;
    width: 133px;
    height: 25px;
    border-radius: 8px;
    color: white;
    text-align: center;
    font-family: 'PT Sans', sans-serif;
    font-size: 13px;
    font-weight: 700;
    line-height: 25px;
    border: none;
    margin-right: 10px;
}

.btn:hover {
    cursor: pointer;
    background: #2a6d9e;
}

.selected {
    background: #3C96D4;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5) inset;
}