.backGround {
  position: absolute; /* Fixed positioning makes the div stick to the viewport */
  top: 0; /* Aligns the div with the top of the page */
  left: 0; /* Aligns the div with the left of the page */
  width: 100vw; /* Sets the width to the full width of the viewport */
  height: 100vh;  /* Sets the height to the full height of the viewport */
  z-index: 9999; /* Ensures that the div is displayed above all other content on the page */
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;

  overflow-y: auto; /* This will enable scrolling if the content overflows vertically */
  box-sizing: border-box; /* Include padding in the height calculations */
}

.form {
  position: absolute; /* Fixed positioning makes the div stick to the viewport */
  top: 10px; /* Aligns the div with the top of the page */
  display: flex;
  flex-direction: column;
  background-color: #EAF2F9;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  padding-inline: 24px;
  border-radius: 8px;

  max-width: 100%; /* Ensure max-width is 100% of the parent */
}

.momentTitle {
  text-align: center;
  font-size: 36px;
  font-family: 'Baloo Bhai', cursive;
  font-weight: bold;
  color: #3C96D4;
}

.sectionTitle {
  width: 100%;
  color: black;
  text-align: left;
  font-size: 24px;
  font-family: 'Baloo Bhai', cursive;
  font-weight: bold;
}

.dataTitle {
  font-family: 'PT Sans', sans-serif;
  color: #949494;
  font-size: 12px;
  text-align: right;
  padding: 10px 0px 10px 0px;
  max-height: 100px;
  margin-bottom: 10px;
}

.dataTitleV2 {
  font-family: 'PT Sans', sans-serif;
  color: #949494;
  font-size: 12px;
  text-align: right;
  padding: 2px 0px 10px 0px;
  max-height: 100px;
  margin-bottom: 10px;
}

.dataTitleV2WithSub {
  font-family: 'PT Sans', sans-serif;
  color: #949494;
  font-size: 12px;
  text-align: right;
  padding: 2px 0px 0px 0px;
  max-height: 100px;
  margin-bottom: 2px;
}
.dataSubTitle {
  color: #949494;

  text-align: right;
  font-family: "PT Sans";
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.NonEditable {
  font-family: 'PT Sans', sans-serif;
  font-size: 13px;
  font-weight: bold;
  text-align: left;
  padding: 10px 9px 10px 0px;
}

.smallCommentHolder {
  width: calc(100% - 15px);
}

.TextBoxHolder {
  padding-top: 6px;
  height: 20px;
}

.largeCommentHolder {
  width: calc(100% - 15px);
  padding-top: 6px;
  height: 40px;
  padding-bottom: 10px
}

.commentHolder {
  padding-top: 6px;
  width: 100%;
}

.Comment {
  padding-left: 7px;
  padding-right: 4px;
  font-size: 13px;
  font-weight: bold;
  width: calc(100% - 23px);
  height: 40px;
  resize: none;
  border-radius: 9px;
  border: 1px solid #3C96D4;
  font-family: 'PT Sans';
  -webkit-transition: 0.5s;
  transition: 0.2s;
  outline: #D9D9D9;
}

.Comment:focus {
  border: 1px solid #c4c4c4;
}

.Comment::placeholder {
  color: #242424;
}

.List {
  padding: 0px;
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 9px;
  margin: 0px;
  width: 100%;
}

.dataRow {
  display: contents;
  width: 100%;
}

.updateAndCancel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  margin-top: 20px;
}

.updateButton {
  width: 65px;
  height: 25px;
}

.cancelButton {
  margin-left: 10px;
  width: 65px;
  height: 25px;
}

.blueLogo {
  width: 25px;
  height: 40px;
  padding-bottom: 20px;
}