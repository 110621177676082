.InvoicingMainContent {
    display: flex;
    flex-direction: column;
    padding-inline: 24px;
    height: calc(100vh - 80px);
    overflow: auto;
    overflow-x: hidden;
}

.InvoicingMainContent::-webkit-scrollbar {
    width: 9px;
}

.InvoicingMainContent::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

.InvoicingMainContent::-webkit-scrollbar-thumb {
    background-color: #3C96D4;
    border-radius: 5px;
}

.InvoicingTitle {
    font-family: 'PT Sans', cursive;
    font-weight: bold;
    font-size: 24px;
    padding-top: 20px;
    padding-bottom: 26px;
}

.InvoicingSubTitle {
    color: #292929;
    font-family: 'PT Sans', Caption;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 20px;
    margin-bottom: 20px;
}

.InvoicingTableLayout {
    width: 100%;
    /* Ensure the table takes up full width of the parent */
    overflow-x: auto;
    /* Allow horizontal scrolling if the table is too wide */
}

.Container {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 24px;
}

.column {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.card-container {
    min-width: 142px;
    height: 97px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 14px;
    background: #FFF;
}

.card-content {
    text-align: center;
}

.card-title {
    margin-bottom: 8px;
    font-family: 'PT Sans', cursive;
    color: #000000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.card-amount {
    font-family: 'PT Sans', cursive;
    color: #000000;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.invSummaryTable {
    margin-top: 27px;
    height: 400px;
    overflow: auto;
    /* Allow the summary table to scroll if it overflows */
}

.TotalFeeForTeam {
    color: #292929;
    font-family: 'PT Sans', Caption;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.dropdownContainer {
    flex-grow: 0;
    flex-shrink: 0;
    width: 130px;
    max-width: 130px;
}

.Btn {
    width: auto;
    height: 25px;
    padding-inline: 10px;

    border-radius: 8px;
    background: #3C96D4;

    color: white;
    text-align: center;

    font-family: 'PT Sans', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;

    cursor: pointer;
}

.Btn:hover {
    background-color: #2a6d9e;
}

.divNoInfo {
    padding-top: 15px;
    text-align: center;
    color: #4a60a8;
    font-weight: bold;
}