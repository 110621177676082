.tableContainer {
  border-radius: 6px;
  background-color: #ffffff;
  height: 100%;
  overflow-y: auto;
  display: block;
}

.tableContainer::-webkit-scrollbar {
  width: 6px;
  /* This is for the vertical scrollbar */
  height: 6px;
}

.tableContainer::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.tableContainer::-webkit-scrollbar-thumb {
  background-color: #3C96D4;
  border-radius: 5px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
  /* changed from visible to auto */
}

.tableHeader {
  white-space: nowrap;
  position: sticky;
  top: 0px;
  background-color: #ffffff;
  z-index: 100;
  /* Or any number you want that's higher than the rest of the elements */
  border-bottom: 1px solid #ECEEF6;
}

.tableBottom {
  white-space: nowrap;
  position: sticky;
  bottom: 0px;
  background-color: #ffffff;
  z-index: 100;
  /* Or any number you want that's higher than the rest of the elements */
  border-bottom: 1px solid #ECEEF6;
}

.tdImage {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 14px;
  margin: 8px 0 8px 8px;
}

.trEditIcon:hover {
  cursor: pointer;
}

.bottomBorder {
  height: 2px;
  width: 100%;
  background-color: #ECEEF6;
  z-index: 100;
}

.th {
  font-size: 12px;
  font-weight: normal;
  font-family: 'PT Sans', sans-serif;
  color: #949494;
  height: 29px;
  text-align: left;
}

.headerStyle {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: normal;
  font-family: 'PT Sans', sans-serif;
  color: #949494;
  height: 29px;
  text-align: left;
  padding-left: 15px;
  padding-right: 15px;
  width: calc(105% -30px);
}

.th {
  height: 30px;
  padding: 0 15px;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
  /* width: 100%; */
}

.td {
  padding: 0 15px;
  /* Change the value to increase/decrease the space */
  height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  /* Optional: Helps ensure the content doesn't overflow the parent */
}

.tdNoInfo {
  padding-top: 15px;
  text-align: center;
  color: #4a60a8;
  font-weight: bold;
}

.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.ellipsis-div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tbody {
  font-size: 12px;
  font-weight: normal;
  font-family: 'PT Sans', sans-serif;
  color: #000000;
  z-index: 99;
}

.bottomBorderHeader {
  display: flex;
  flex-direction: row;
}

.SuppliersListData_Yes {
  color: #0B8A00;
  text-align: center;
}

.SuppliersListData_No {
  color: #D32030;
  text-align: center;
}

.OpenSicknessData_Return,
.SicknessLogData_SignOff,
.InvoicingReq_Done {
  width: 84px;
  height: 25px;
  border-radius: 8px;
  background-color: #3C96D4;
  color: white;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SicknessLogData_Yes {
  color: #0B8A00;
  text-align: center;
}

.SicknessLogData_No {
  color: #D32030;
  text-align: center;
}

.ReviewsTODOButton,
.ReviewsDRAFTButton {
  width: 83px;
  height: 25px;
  background-color: #3C96D4;
  color: #ffffff;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
}

.ReviewsDRAFTButton {
  background-color: #0B8A00;
}

.ReviewsDRAFTButton:hover {
  background-color: #096800;
}

.ReviewsViewButton {
  width: 83px;
  height: 25px;
  background-color: #3C96D4;
  color: #ffffff;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  font-size: 13px;
}

.ReviewsViewButton:hover,
.ReviewsTODOButton:hover {
  background-color: #2a6d9e;
}

.ReviewsNoViewButton {
  width: 83px;
  height: 25px;
  background-color: #949494;
  color: #ffffff;
  border-radius: 8px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
}

.rowHoverHighlight:hover {
  cursor: pointer;
  background-color: rgb(243, 243, 243);
}

.CenterContent,
.ReviewsViewButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.RightAlign {
  display: flex;
  align-items: end;
  justify-content: right;
}

.smallerColumn {
  max-width: 0px !important;
  width: 0px !important;
}

.largerColumn {
  width: 20% !important;
}

.StatusOpp {
  color: #9932EA;
}

.StatusLive {
  color: #0B8A00;
}

.StatusOnHold {
  color: #D32030;
}

.StatusDormant {
  color: #5F5F5F;
}

.StatusQuote {
  color: #3C96D4;
}



.Tag {
  background-color: #F37021;
  color: #FFFFFF;
  border-radius: 8px;
  font-weight: bold;
  font-size: 14px;
  padding-inline: 15px;
  max-width: 8px;
  height: 25px;
  text-align: center;
  justify-content: center;
  display: flex;
  /* Add this to use flexbox */
  align-items: center;
}

.numberInput {
  height: 16px;
  width: 30px;
  border: solid 1px #3C96D4;
  border-radius: 4px;
  font-size: 12px;
  text-align: center;
}

.numberInput:focus {
  border: solid 1px #b8b8b8;
  outline: none;
}