.Button {
    font-weight: bold !important;
    color: #ffffff !important;

    border-radius: 8px !important;
    background: #3C96D4 !important;

    /* BUTTON */
    font-family: 'PT Sans', sans-serif !important;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
    min-width: 36px;

    text-wrap: nowrap;

    height: 25px;
    cursor: pointer;
    border: solid 2px rgba(255, 255, 255, 0);
}

.Disabled {
    background-color: lightgray !important;
    cursor: not-allowed !important;
}