.backGround {
    position: fixed; /* Fixed positioning makes the div stick to the viewport */
    top: 0; /* Aligns the div with the top of the page */
    left: 0; /* Aligns the div with the left of the page */
    width: 100vw; /* Sets the width to the full width of the viewport */
    height: 100vh; /* Sets the height to the full height of the viewport */
    z-index: 999990; /* Ensures that the div is displayed above all other content on the page */
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .form {
    display: flex;
    flex-direction: column;
    width: 360px;
    background-color: #EAF2F9;
    justify-content: center;
    align-items: center;
    z-index: 999990;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 8px;
  }

  .momentTitle {
    text-align: center;
    font-size: 36px;
    font-family: 'Baloo Bhai', cursive;
    font-weight: bold;
    color: #3C96D4;
  }

  .sectionTitle {
    width: 100%;
    padding-left: 24px;
    text-align: left;
    font-size: 24px;
    font-family: 'Baloo Bhai', cursive;
    font-weight: bold;
  }

  .dataSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
  }

  .dataTitle, .doubleRowDataTitle, .dataTitleWithExplanation, .Explanation {
    display: flex;
    justify-content: right;
    align-items: center;
    font-family: 'PT Sans', sans-serif;
    color: #949494;
    font-size: 12px;
    text-align: right;
    width: 115px;
    margin-left: 25px;
    margin-right: 25px;
  }

  .Explanation {
    font-size: 8px;
  }

  .doubleRowDataTitle {
    width: 160px;
  }

  .NonEditable, .doubleRowNonEditable, .NonEditableWithExplanation, .LongTExt {
    font-family: 'PT Sans', sans-serif;
    font-size: 13px;
    font-weight: bold;
    text-align: left;
    padding: 10px 9px 10px 0px;
  }

  .NonEditableWithExplanation {
    padding: 0px;
    margin-right: 10px;
  }

  .doubleRowNonEditable, .LongTExt {
    padding: 10px 10px 10px 0px;
  }

  .LongTExt {
    width: 375px;
  }

  .smallCommentHolder {
    width: calc(100% - 15px);
    padding-top: 6px;
    height: 20px;
  }

  .largeCommentHolder {
    width: calc(100% - 15px);
    padding-top: 6px;
    height: 40px;
    padding-bottom: 10px
  }

  .commentHolder {
    padding-top: 6px;
    width: calc(100% - 15px);
  }

  .Comment {
    padding-left: 7px;
    padding-right: 4px;
    font-size: 13px;
    font-weight: bold;
    width: calc(100% - 23px);
    height: 40px;
    resize: none;
    border-radius: 9px;
    border: 1px solid #3C96D4;
    font-family: 'PT Sans';
    -webkit-transition: 0.5s;
    transition: 0.2s;
    outline: #D9D9D9;
  }
  .Comment:focus {
    border: 1px solid #c4c4c4;
  }
  .Comment::placeholder {
    color: #242424;
  }

  .List {
    padding: 0px;
    display: grid;
    grid-template-columns: max-content 1fr;
    column-gap: 9px;
    margin: 0px;
    width: 100%;
  }

  .dataRow {
    display: contents;
    width: 100%;

  }

  .updateAndCancel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    margin-top: 20px;
  }

  .updateButton{
    width: 65px;
    height: 25px;
  }
  .cancelButton {
    margin-left: 10px;
    width: 65px;
    height: 25px;
  }

  .blueLogo {
    width: 25px;
    height: 40px;
    padding-bottom: 20px;
  }

  .row, .doubleRow, .YesNoTicks{
    display: flex;
    flex-direction: row;
    justify-content: left;
  }

  .doubleRow{
    display: flex;
    flex-direction: row;
    justify-content: left;
  }
  
  .dataTitleWithDescription {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .YesNoTicks{
    align-items: center;
  }

  .Yes, .No {
    font-family: 'PT Sans';
    color: #949494;
    font-size: 12px;
    padding: 8px;
  }

  .DraftButton {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: #FFFFFF;
    background-color: #0B8A00;
    border: none;
    border-radius: 6px;
    height: 28px;
    cursor: pointer;
    white-space: nowrap;
    width: 87px;
    margin-top: 2px;
    margin-right: 10px;
  }
  
  .DraftButton:hover {
    background-color: #086400;
  }

  .UPLOAD {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #FFFFFF;
    background-color: #3C96D4;
    border: none;
    border-radius: 6px;
    min-width: 38px;
    height: 100%;
    cursor: pointer;
    border: solid 2px rgba(255, 255, 255, 0);
    height: 25px;
    cursor: pointer;
    padding-left: 5px;
    padding-right: 5px;
  }
  
  .UPLOAD:hover {
    background-color: #2a6d9e;
  }

  .bookingLeaveRowComment {
    height: 47px;
    width: 300px;
    margin-bottom: 5px;
  }

  .nonBookingLeaveComment{
    padding-left: 4px;
    padding-right: 4px;
    font-size: 12px;
    width: 300px;
    height: 40px;
    resize: none;
    border-radius: 9px;
    border: 1px solid #3C96D4;
    font-family: 'PT Sans';
    -webkit-transition: 0.5s;
    transition: 0.2s;
    outline: #D9D9D9;
    color: #949494;
  }
  
  .bookingLeaveComment {
    padding-left: 4px;
    padding-right: 4px;
    font-size: 12px;
    font-weight: bold;
    width: 300px;
    height: 40px;
    resize: none;
    border-radius: 9px;
    border: 1px solid #3C96D4;
    font-family: 'PT Sans';
    -webkit-transition: 0.5s;
    transition: 0.2s;
    outline: #D9D9D9;
  }

  .bookingLeaveComment:focus {
    border: 1px solid #c4c4c4;
  }
  
  .nonBookingLeaveComment::placeholder {
    color: #D9D9D9;
  }

  .fileName {
    display: flex;
    align-items: center;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 12px;
    width: 210px;
    height: 25px;
    resize: none;
    border-radius: 9px;
    border: 1px solid #3C96D4;
    font-family: 'PT Sans';
    background-color: white;
    margin-right: 25px;
    margin-left: 10px;
  }