.backgroundOfDatePicker {
  display: flex;
  justify-content: center; 
  align-items: center;
  position: fixed;
  inset: 0;
  background: rgba(0,0,0,0.5);
  z-index: 1000000000;
}

.inputDateLeaveProfile {
  position: relative;
  width: calc(100% - 8px);
  padding: 0px;
  padding-left: 8px;
  margin-bottom: 5px;
  background-color: #fff;
  border-radius: 8px;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  z-index: 1;
}


.datePicker {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.cardCalendarIcon {
  position: absolute;
  cursor: pointer;
  margin-right: 10px;
  z-index: 2;
}

.calendarIcon {
  position: relative;
  right: 30px;
  top:-5px;
  cursor: pointer;
  margin-right: 10px;
  z-index: 2;
}

.inputContainer {
  position: relative;
  width: 100%;
}

.iconContainer {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}