/* Topbar.module.css */
.Topbar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px;
  margin-bottom: 0px;
  margin-top: 90px;
  box-shadow: 0 20px 20px 0 #eaf2f9;
  z-index: 99;
}

.ButtonsWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.TopbarList {
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  z-index: 99;
}
.row {
  text-decoration: none;
}

.row:visited { text-decoration: none; color:#000 }

.TopbarInner {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
}

.row {
  display: block;
  padding: 5px 10px 5px 10px;
  margin: 0px 10px 0px 10px;
  position: relative;
  border: none;
  border-top: 1px solid;
  border-color: #eaf2f9;
  white-space: nowrap;
  font-size: 14px;
  font-weight: bold;
}

.row.disabled {
  color: #d9d9d9;
}

.TopbarList .row:active {
  color: #000000;
  outline: none;
}

.row.ClickedOn {
  border-bottom: 1px solid #3c96d4;
  border-right: 1px solid #eaf2f9;
  border-left: 1px solid #eaf2f9;
  border-top: 1px solid #eaf2f9;
  border-radius: 8px 8px 0 0;
  color: #3c96d4;
}

.row::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  border: 1px solid #eaf2f9;
  opacity: 0.5;
}

.line {
  position: absolute;
  width: calc(100% - 20px);
  height: 0.5px; /* Adjust the height according to your design */
  background-color: #e0e0e0;
  z-index: 10;
  bottom: 0.5px; /* Adjust this value based on the height of the bottom border of the buttons */
}

.LineWrapper {
  height: 50px; /* Adjust the height according to your design */
  width: 1px; /* Adjust the width according to your design */
  margin-right: 24px; /* Add some space between the line and the buttons */
}

.shadow {
  position: absolute;
  background: linear-gradient(to bottom, black 50%, transparent);
}
