.OpportunitiesMainContent {
    display: flex;
    flex-direction: column;
    padding-left: 24px;
    padding-right: 22px;
    margin-right: 2px;
    height: calc(100vh - 80px); /* Adjust the value based on the heights of the top navigation bar and any other elements that might affect the height */
    overflow: auto;
    overflow-x: hidden;
}

.OpportunitiesMainContent::-webkit-scrollbar {
    width: 9px;
}
.OpportunitiesMainContent::-webkit-scrollbar-track {
    background-color: #f1f1f1;
} 
.OpportunitiesMainContent::-webkit-scrollbar-thumb {
    background-color: #3C96D4;
    border-radius: 5px;
}

.OpportunitiesTitle {
    font-family: 'Baloo Bhai', cursive;
    font-weight: bold;
    font-size: 24px;

}

.OpportunitiesTableFilters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.Filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.statusFilter {
    display: flex;
    align-items: center;
    z-index: 110;
  }

.dropdownContainer {
    flex-grow: 0; /* Prevent the container from growing */
    flex-shrink: 0; /* Prevent the container from shrinking */
    width: 65px; /* Set a fixed width for the container */
    max-width: 65px; /* Ensure the width doesn't exceed 130px */
  }

.FilterLabel {
    margin-right: 10px;
    font-weight: normal;
    color: #949494;
}

.AddBtn {
    width: 83px;
    height: 25px;
    /* flex-shrink: 1; */
    margin-right: 20px;

    border-radius: 8px;
    background: #3C96D4;

    color: white;
    text-align: center;

    font-family: 'PT Sans', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;

    cursor: pointer;
} 

.AddDisabled {
    background-color: lightgray;
    cursor: not-allowed;
}

.AddBtn:hover {
    background-color: #2a6d9e;
}

.AddDisabled:hover {
    background-color: lightgray !important;
}

.SearchBar {
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}





.TableLayout {
    width: 100%;
    height: 317px;
    flex-shrink: 0;

    border-radius: 6px;
    border: 2px solid #ECEEF6;
    background: #FFF;
}

.TimesheetTotals {
    width: 66%;
    height: 40px;
    flex-shrink: 0;

    border-radius: 6px;
    border: 2px solid #ECEEF6;
    background: #FFF;
}

.TimesheetPagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.TimesheetButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.PaginationDisplay {
    width: 83px;
    height: 25px;
    flex-shrink: 0;
    margin-inline: 10px;
    margin-bottom: 5px;

    border-radius: 8px;
    border: 2px solid #3C96D4;
    background: #FFF;

    color: #3C96D4;
    text-align: center;

    font-family: 'PT Sans', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;

    cursor: pointer;
} 

.OpportunitiesTable {
    width: 100%;
    border-collapse: collapse;
    table-layout: initial;
    table-layout: fixed;
    margin-block: 11.25px;
}

.EmptyCell, .Total {
    flex-grow: 0;   /* Prevents the cells from growing */
    flex-shrink: 0; /* Prevents the cells from shrinking */
}

.TimesheetTotals .OpportunitiesTable{
    max-width: 100%;
}
.TimesheetTotals .OpportunitiesTable .EmptyCell {
    width: 8.34%;
}
.TimesheetTotals .OpportunitiesTable .TotalTxt {
    width: 21.2%;
    text-align: left;
}
.TimesheetTotals .OpportunitiesTable .TotalDay {
    width: 8.34%;
}
.TimesheetTotals .OpportunitiesTable .Total {
    max-width: calc(100% - 25%);
}

.OpportunitiesTable th {
    text-align: center;
    
    color: #949494;
    font-family: 'PT Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-bottom: 1px solid #ECEEF6;
}

.OpportunitiesTable th.jobNo {
    width: 5.5%;
}

.OpportunitiesTable th.address {
    width: 14%;
    text-align: left;
}

.OpportunitiesTable th.day {
    width: 5.5%;
}

.OpportunitiesTable th.level {
    width: 5%;
}

.OpportunitiesTable th.client {
    width: 14%;
    text-align: left;
}

.OpportunitiesTable th.tags {
    flex-grow: 1; /* This will allow the tags column to take up the remaining width */
    text-align: left;
}

.OpportunitiesTable td {
    text-align: center;
    
    color: #000;
    font-family: 'PT Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.12px;
}

.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 130px;
    margin-bottom: 20px;
}


.Btn {
    width: auto;
    height: 25px;
    padding-inline: 10px;

    border-radius: 8px;
    background: #3C96D4;

    color: white;
    text-align: center;

    font-family: 'PT Sans', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;

    cursor: pointer;
}

.Btn:hover {
    background-color: #2a6d9e;
}

.AddBtn {
    width: 83px;
    height: 25px;
    /* flex-shrink: 1; */
    margin-right: 20px;

    border-radius: 8px;
    background: #3C96D4;

    color: white;
    text-align: center;

    font-family: 'PT Sans', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;

    cursor: pointer;
}

.AddDisabled {
    background-color: lightgray;
    cursor: not-allowed;
}

.AddBtn:hover {
    background-color: #2a6d9e;
}

.AddDisabled:hover {
    background-color: lightgray !important;
}

.ButtonContainer {
    display: flex;
    justify-content: flex-end; /* Align to right */
    gap: 10px; /* Spacing between buttons */
    /* Other styles such as padding, margin, etc., as needed */
}