.YesButtonComponentStyling {
    justify-content: center;
    align-content: center;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #FFFFFF;
    background-color: #3C96D4;
    border: none;
    border-radius: 6px;
    width: 38px;
    height: 25px;
    cursor: pointer;
  }
  
  .YesButtonComponentStyling:hover {
    background-color: #2a6d9e;
  }
  
  .NoButtonComponentStyling {
    justify-content: center;
    align-content: center;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #3C96D4;
    background-color: #FFFFFF;
    border: solid 2px #3C96D4;
    border-radius: 6px;
    width: 36px;
    height: 25px;
    cursor: pointer;
  }
  
  .NoButtonComponentStyling:hover {
    background-color: #f3f3f3;
  }