.commentModal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.commentModal {
    background: #EAF2F9;
    padding: 20px;
    border-radius: 8px;

    width: 369px;
    height: 257px;
}

.commentModal-dropSection {
    border-radius: 8px;
    margin: 25px 25px 0px 25px;
    border: 1px dashed #3C96D4;
    background: #FFF;

    height: 67px;
    flex-shrink: 0;

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    cursor: pointer;
}

.commentModal-txt {
    color: #3C96D4;
    font-family: "PT Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.12px;

    margin-top: unset;
    margin-bottom: 5px;
}

.commentModal-commentSection {
    padding: 25px;
    height: 67px;
}


.commentModal-buttons {
    display: flex;
    justify-content: space-between;
}

.commentModal-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: blue;
    color: white;
}

.commentModal-buttons button:nth-child(2) {
    background-color: #ccc;
    color: black;
}

.file-list-container {
    max-height: 67px;
    overflow-y: auto;
}

.file-list-container::-webkit-scrollbar {
    width: 9px;
}

.file-list-container::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

.file-list-container::-webkit-scrollbar-thumb {
    background-color: #3C96D4;
    border-radius: 5px;
}

.remove-file-icon {
    cursor: pointer;
    padding: 0 8px;
    color: #999;
}