  @font-face {
    font-family: 'PT Sans';
    src: url('./assets/ptSansFontFamily/PTSans-Regular.woff2') format('woff2'),
         url('./assets/ptSansFontFamily/PTSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'PT Sans';
    src: url('./assets/ptSansFontFamily/PTSans-Bold.woff2') format('woff2'),
         url('./assets/ptSansFontFamily/PTSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'PT Sans';
    src: url('./assets/ptSansFontFamily/PTSans-Italic.woff2') format('woff2'),
         url('./assets/ptSansFontFamily/PTSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
  }
  @font-face {
    font-family: 'PT Sans';
    src: url('./assets/ptSansFontFamily/PTSans-BoldItalic.woff2') format('woff2'),
         url('./assets/ptSansFontFamily/PTSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
  }
        