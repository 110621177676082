.mainContent {
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 24px;
  overflow: auto;
  height: calc(100% - 120px);
}

.mainContent::-webkit-scrollbar {
  width: 9px;
}
.mainContent::-webkit-scrollbar-track {
  background-color: #f1f1f1;
} 
.mainContent::-webkit-scrollbar-thumb {
  background-color: #3C96D4;
  border-radius: 5px;
}


.topSectionDiv, .bottomSectionDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.topSectionDiv {
  height: 368.6px;
}

.bottomSectionDiv {
  height: calc(100% - 368.6px);
}


.LogEmployeeSicknessSection, .OpenSicknessSection, .SicknessOverviewSection, .SicknessLogSection {
  display: flex;
  flex-direction: column;
}

.SicknessOverviewSection {
  display: flex;
  flex-grow: 1;
}

.LogEmployeeSicknessSection {
  width: calc(260px + 72px);
}

.SicknessLogSection {
  flex-grow: 1;
}

.title {
  font-family: 'Baloo Bhai', cursive;
  font-weight: bold;
  font-size: 24px;
  margin-top: 27px;
  margin-bottom: 20px;
  width: 100%;
  white-space: nowrap;
}

.OpenSicknessTable, .SicknessOverviewTable, .SicknessLogTable {
  height: 280px;
  width: calc(100% - 25px);
  margin: 0 24px 0 0;
  border: solid 2px #ECEEF6;
  border-radius: 8px;
  background-color: white;
}

.OpenSicknessTable{
  width: 800px;
}

.SicknessLogTable {
  margin-right: 0px;
  width: 100%;
}

.SicknessOverviewTable, .SicknessLogTable {
  flex-grow: 1;
  margin-bottom: 24px;
  overflow: auto;
  height: 10px;
}

.LogEmployeeSicknessForm {
  width: calc(260px + 45px);
  padding: 24px;
  padding-left: 23px;
  padding-right: 0px;
  border: solid 2px #ECEEF6;
  border-radius: 8px;
  background-color: white;
}

.FormTextAndInputSection {
  display: flex;
  width: 130px;
  margin-right: 23px;
  flex-direction: column;
}

.FormRowSection {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.FormInput {
  width: 130px;
  margin-bottom: 19px;
}

.FormText {
  color: #949494;
  font-size: 12px;
  margin-bottom: 10px;
}

.bookingLeaveComment {
  padding-left: 4px;
  padding-right: 4px;
  font-size: 12px;
  width: 276px;
  height: 40px;
  resize: none;
  border-radius: 9px;
  border: 1px solid #3C96D4;
  font-family: 'PT Sans';
  -webkit-transition: 0.5s;
  transition: 0.2s;
  outline: #D9D9D9;
}

.nonBookingLeaveComment{
  padding-left: 4px;
  padding-right: 4px;
  font-size: 12px;
  width: 276px;
  height: 40px;
  resize: none;
  border-radius: 9px;
  border: 1px solid #3C96D4;
  font-family: 'PT Sans';
  -webkit-transition: 0.5s;
  transition: 0.2s;
  outline: #D9D9D9;
  color: #949494;
}

.bookingLeaveComment:focus {
  border: 1px solid #c4c4c4;
}

.bookingLeaveComment::placeholder {
  color: #D9D9D9;
}

.bookingLeaveRowComment {
  height: 47px;
  width: 282px;
  margin-bottom: 19px;
}

.SubmitButton {
  justify-content: center;
  align-content: center;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  background-color: #3C96D4;
  border: none;
  border-radius: 6px;
  width: 83px;
  height: 25px;
  line-height: 25px;
  cursor: pointer;
}

.SubmitButton:hover {
  background-color: #2a6d9e;
}

.submitdiv {
  justify-content: center;
  align-content: center;
  text-align: center;
  margin: none;
  padding: none;
}

.LoadOverlay {
  background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent background */
  position: fixed; /* Cover the entire screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000; /* Ensure it's above everything else */
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
}

.clalanderIcon {
  position: absolute;
  z-index: 1;
  top: 28px;
  right: 3px;
}

.backGround {
  position: fixed; /* Fixed positioning makes the div stick to the viewport */
  top: 0; /* Aligns the div with the top of the page */
  left: 0; /* Aligns the div with the left of the page */
  width: 100vw; /* Sets the width to the full width of the viewport */
  height: 100vh; /* Sets the height to the full height of the viewport */
  z-index: 999990; /* Ensures that the div is displayed above all other content on the page */
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  display: flex;
  flex-direction: column;
  width: 360px;
  background-color: #EAF2F9;
  justify-content: center;
  align-items: center;
  z-index: 999990;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 8px;
}