.YesButtonComponentStyling {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  background-color: #3C96D4;
  border: none;
  border-radius: 6px;
  min-width: 36px;
  width: 100%;
  height: 25px;
  cursor: pointer;
  border: solid 2px rgba(255, 255, 255, 0);
  cursor: pointer;
}

.YesButtonComponentStyling:hover {
  background-color: #2a6d9e;
}

.NoButtonComponentStyling {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  font-weight: bold;
  color: #3C96D4;
  background-color: #FFFFFF;
  border: solid 2px #3C96D4;
  border-radius: 6px;
  min-width: 36px;
  width: 100%;
  height: 25px;
  cursor: pointer;
}

.NoButtonComponentStyling:hover {
  background-color: #f3f3f3; /* Change the background color */
}