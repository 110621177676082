.resizeHandle {
  position: absolute;
  right: 0;
  top: 0;
  width: 1px;
  height: 20px;
  background-color: red;
}

.tableHeaders {
  position: relative;
}