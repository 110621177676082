.projectContent {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    margin-right: 24px;
    height: calc(100vh - 7px);
  }
  
.ProjectHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 130px;
  margin-bottom: 20px;
}

.ButtonGroup {
  display: flex;
  align-items: center;
  gap: 10px;
}

.Projecttitle {
    font-family: 'Baloo Bhai', cursive;
    font-weight: bold;
    font-size: 24px;
}

.ProjectListBox table {
  width: 100%;
  max-width: 100%; /* Add this line */
  border-collapse: collapse;
  table-layout: initial;
}


.ProjectListBox {
    background-color: #FFFFFF;
    border: 2px solid #ECEEF6;
    max-width: 200vw;
    border-radius: 6px;
    position: relative; /* Add this to create a new positioning context */
    
    overflow-y: scroll;
    overflow-x: hidden;
}

/* Customize the scrollbar Sidebar */
.ProjectListBox::-webkit-scrollbar {
  width: 7px; /* Adjust the width of the scrollbar */
}

/* Customize the scrollbar track */
.ProjectListBox::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Set the background color of the track */
  border: 15px;
}

/* Customize the scrollbar thumb */
.ProjectListBox::-webkit-scrollbar-thumb {
  background-color: #3C96D4; /* Set the background color of the thumb */
  border-radius: 10px; /* Add border-radius to make the thumb rounder */
}

/* Customize the scrollbar thumb on hover */
.ProjectListBox::-webkit-scrollbar-thumb:hover {
  background-color: #3C96D4; /* Set the background color of the thumb on hover */
  cursor: pointer; /* Change the mouse cursor to pointer on hover */
}

.ProjectListBox th {
    font-weight: normal;
    font-size: 12px; /* Set the font size of the column names */
    color: #949494; /* Set the color of the column names */
    padding: 15px;
    text-align: left; /* Center the values in the columns */
    cursor: pointer;
    /* z-index: 1; */
    /* width: 5%; Add this to fix the width of the columns */
    white-space: nowrap;
    position: relative;

}

.ProjectListBox thead th
 {
  position: sticky; /* make the table heads sticky */
  top: 0px; /* table head will be placed from the top of the table and sticks to it */
  background: #ffffff;
  overflow: hidden;
  z-index: 2;
  
}

.Btn {
  width: 83px;
  height: 25px;

  border-radius: 8px;
  background: #3C96D4;

  color: white;
  text-align: center;

  font-family: 'PT Sans', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;

  cursor: pointer;
}

.Btn:hover {
  background-color: #2a6d9e;
}

.AddDisabled {
  background-color: lightgray;
  cursor: not-allowed;
}

.AddBtn:hover {
  background-color: #2a6d9e;
}

.AddDisabled:hover {
  background-color: lightgray !important;
}

.ProjectPagination {
  padding-bottom: 30px;
}

.ProjectListBox td {
    font-size: 10px;
    padding: 8px;
    padding-left: 15px;
    font-family: 'PT Sans', sans-serif;
    cursor: pointer;
    white-space: normal;
    justify-content: center;
    align-items: center;
}

.th-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
}

.ProjectListBox #projTableContainer td:nth-child(2),
.ProjectListBox #projTableContainer th:nth-child(2),
.ProjectListBox #projTableContainer td:nth-child(3),
.ProjectListBox #projTableContainer th:nth-child(3),
.ProjectListBox #projTableContainer td:nth-child(6),
.ProjectListBox #projTableContainer th:nth-child(6)
{
  text-align: left;
}

.ProjectListBox #oppTableContainer td:nth-child(2),
.ProjectListBox #oppTableContainer th:nth-child(2),
.ProjectListBox #oppTableContainer td:nth-child(3),
.ProjectListBox #oppTableContainer th:nth-child(3),
.ProjectListBox #oppTableContainer td:nth-child(4),
.ProjectListBox #oppTableContainer th:nth-child(4)
{
  text-align: left;
}

.ProjectListBox #invTableContainer td:nth-child(2),
.ProjectListBox #invTableContainer th:nth-child(2),
.ProjectListBox #invTableContainer td:nth-child(4),
.ProjectListBox #invTableContainer th:nth-child(4),
.ProjectListBox #invTableContainer td:nth-child(14),
.ProjectListBox #invTableContainer th:nth-child(14)
{
  text-align: left;
}

.ProjectListBox #clientTableContainer td:nth-child(2),
.ProjectListBox #clientTableContainer th:nth-child(2),
.ProjectListBox #clientTableContainer td:nth-child(3),
.ProjectListBox #clientTableContainer th:nth-child(3),
.ProjectListBox #clientTableContainer td:nth-child(6),
.ProjectListBox #clientTableContainer th:nth-child(6),
.ProjectListBox #clientTableContainer td:nth-child(8),
.ProjectListBox #clientTableContainer th:nth-child(8),
.ProjectListBox #clientTableContainer td:nth-child(9),
.ProjectListBox #clientTableContainer th:nth-child(9)
{
  text-align: left;
}

.ProjectListBox #suppliersTableContainer td:nth-child(1),
.ProjectListBox #suppliersTableContainer th:nth-child(1),
.ProjectListBox #suppliersTableContainer td:nth-child(2),
.ProjectListBox #suppliersTableContainer th:nth-child(2),
.ProjectListBox #suppliersTableContainer td:nth-child(3),
.ProjectListBox #suppliersTableContainer th:nth-child(3),
.ProjectListBox #suppliersTableContainer td:nth-child(4),
.ProjectListBox #suppliersTableContainer th:nth-child(4),
.ProjectListBox #suppliersTableContainer td:nth-child(5),
.ProjectListBox #suppliersTableContainer th:nth-child(5),
.ProjectListBox #suppliersTableContainer td:nth-child(6),
.ProjectListBox #suppliersTableContainer th:nth-child(6),
.ProjectListBox #suppliersTableContainer td:nth-child(7),
.ProjectListBox #suppliersTableContainer th:nth-child(7),
.ProjectListBox #suppliersTableContainer td:nth-child(8),
.ProjectListBox #suppliersTableContainer th:nth-child(8),
.ProjectListBox #suppliersTableContainer td:nth-child(9),
.ProjectListBox #suppliersTableContainer th:nth-child(9)
{
  text-align: left;
}

.ProjectListBox td,
.ProjectListBox th {
  text-align: center;
  border-bottom: 1px solid #ECEEF6;
}

.td-content {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative; /* Change from absolute to relative */
  width: 100%;
}

.ProjectListBox tr:last-child td {
    border-bottom: none;
}

.StatusLive {
    color: #0B8A00;
  }
  
.StatusOnHold {
    color: #D32030;
}

.StatusDormant {
    color: #5F5F5F;
}

.td-content .TagContainer {
  display: flex;
  gap: 5px; /* Adjust the spacing between tags if needed */
}

.td-content .TagContainer .Tag {
  background-color: #F37021;
  color: #FFFFFF;
  border-radius: 8px;
  font-weight: bold;
  font-size: 10px;
  padding-left: 11px;
  padding-right: 11px;
  max-width: 8px;
  height: 25px;
  text-align: center;
  justify-content: center;
  display: flex; /* Add this to use flexbox */
  align-items: center;
}

.StatusNdTeam {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.ProjectTableFilters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
  }
  
.ProjectTeamFilter,
.ProjectStatusFilter {
  /* display: flex; */
  align-items: center;
  z-index: 9998;
}

.ProjectStatusFilter {
  height: 25px;
  width: 150px;
  margin-left: 25px;
  display: flex;
}

.ProjectTeamFilter {
  display: flex;
  height: 25px;
  width: 150px;
}

.ProjectBIDSwitch {
  height: 25px;
  width: 150px;
  margin-left: 50px;
}

.FilterLabel {
  margin-right: 10px;
  font-weight: normal;
  color: #949494; /* Set the color of the column names */

}

/* .ProjectTeamFilter {
    margin-right: 20px;
    
  } */


  .ProjectListSearch {
    margin-right: 10px;
  }
  
  .pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    font-weight: bold;
    font-size: 12px; /* Set the font size of the column names */
    color: #949494; /* Set the color of the column names */
  }
  
  .pagination-info {
    text-align: center;
  }
  
  .pagination-main {
    display: flex;
    align-items: center;
  }

  .pagination-controls button {
    width: 25px;
    height: 25px;
    left: 358px;
    top: 543px;

    background: #FFFFFF;
    border: 1px solid #3C96D4;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .pagination-right-control button {
    margin-left: 10px;
  }

  .pagination-left-control button {
    margin-right: 10px;
  }

  .pagination-controls button:disabled {
    background: #e0e0e0;
    border: 1px solid #949494;
    border-radius: 8px;

    cursor: not-allowed;
  }

  .resizerBoxcolor {
    width: 1px;
    height: 100%;
    background-color: #dfdfdf;
  }
  
  .resizer {
    display: inline-block;
    background: #ffffff;
    padding: 11px;
    width: 2px;
    margin-top: -11px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    cursor: col-resize;
    /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); */
    /* border-top-left-radius: 5px; */
    /* border-bottom-left-radius: 5px; */
    /* transition: background-color 0.2s ease-in-out; */
  }
  
.resizer:hover,
.resizer.clicked {
  background-color: #ffffff;
}

.NoProjects {
  font-family: 'Baloo Bhai', cursive;
  font-weight: bold;
  font-style: normal;
  font-size: 12px;
  line-height: 19px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: #D9D9D9;
  height: 36px; /* adjust the height as needed */
}

button.disabled {
  background-color: lightgray;
  cursor: not-allowed;
}

.row-hover {
  background-color: rgb(243, 243, 243);
}

.row-selected {
  background-color: #d9edff;
}