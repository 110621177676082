.dashboardContainer {
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 22px;
  margin-right: 2px;
  height: calc(100vh);
  overflow: auto;
}

.dashboardContainer a {
  text-decoration: none;
  color: #292D32;
}

.dashboardContainer a:active {
  color: inherit;
}

.dashboardContainer::-webkit-scrollbar {
  width: 9px;
}

.dashboardContainer::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.dashboardContainer::-webkit-scrollbar-thumb {
  background-color: #3C96D4;
  border-radius: 5px;
}

.dashboardInvoicing {
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 22px;
  margin-right: 2px;
  overflow: auto;
}