.Comment {
    display: flex;
    align-items: center;
    color: #949494;
    width: 100%;
  
    line-height: 18px;
    margin-top: -5px;
    margin-left: 10px;
  }
  
  .CommentInput {
    min-width: 507px;
    height: 50px;
    font-family: 'PT Sans';
    background: #FFFFFF;
    border: 1px solid #3C96D4;
    border-radius: 8px;
    overflow-wrap: break-word;
    resize: none;
    flex-grow: 1;
  
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
  
    margin-top: 32px;
    margin-left: 20px;
  }
  
  .CommentInput::placeholder {
    color: #D9D9D9;
  }
  
  .CommentInput:focus {
    border: 1px solid #D9D9D9;
  }
  
  .CommentInput.invalid {
    border: 2px solid red;
    animation: pulsate 0.5s ease-in-out;
  }
  
  @keyframes pulsate {
    0% {
      border-color: red;
    }
    50% {
      border-color: transparent;
    }
    100% {
      border-color: red;
    }
  }