.LoginPage {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.SignInButton {
    background-color: white;
    border: 2px solid #3C96D4;
    border-radius: 8px;
    font-size: 16px;
    padding: 15px;
    padding-top: 7.5px;
    padding-bottom: 7.55px;
    font-family: 'PT Sans', sans-serif;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 100px;
}

.SignInButton:active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.2);
}

.LoginPageText {
    margin-bottom: 50px;
    font-size: 26px;
    font-family: 'PT Sans', sans-serif;
}

.LogOut {
    margin-bottom: calc(8% + 3.5px);
    height: 33px;
    margin-top: 3.5px;
    border-radius: 0 9px 9px 0;
    width: 100%;
    display: flex;
    align-self: center;
    cursor: pointer;
    font-size: 14px;
}

.LogOut:hover {
    background-color: #EAF2F9;
}

.LogOutIcon{
  width: 19px;
  height: 19px;
  align-self: center;
  padding-right: 14px;
  margin-left: 30px;
}

.logoutText {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    font-weight: bold;
    text-align: center;
}