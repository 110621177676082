  .dropdown {
    position: relative;
    display: inline-block;
    user-select: none;
    width: 100%;
    z-index: 5;
  }

  .dropdownSelected {
    height: 15px;
    /* min-width: 120px; */
    padding-left: 8px;
    padding-top: 2px;
    padding-bottom: 6px;
    background-color: #fff;
    border: 1px solid #3C96D4;
    border-radius: 8px;
    cursor: pointer;
    font-size: 13px;
    font-weight: bold;
    z-index: 5;
  }

  .dropdownOptions {
    max-height: 160px;
    position: absolute;
    left: 0;
    width: calc(100% - 3px); 
    background-color: #fff;
    border-top: none;
    border-radius: 10px;
    z-index: 5;
    overflow-x: hidden;
    border: 1px solid #3C96D4;
    border-radius: 8px 8px 8px 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    white-space: nowrap;
  }

  .dropdownOption {
    align-items: center;
    height: 15px;
    /* min-width: 108px; */
    padding-left: 5px;
    margin-inline: 3px;
    padding-top: 1px;
    margin-top: 1px;
    padding-bottom: 5px;
    margin-bottom: 1px;
    background-color: #fff;
    border-radius: 8px;
    cursor: pointer;
    font-size: 13px;
    font-weight: bold;
    z-index: 100;
  }
  
  .dropdownOption:hover {
    background-color: #EAF2F9;
    font-weight: bold;
    z-index: 5;
  }

  .dropdownOptions::-webkit-scrollbar {
    width: 4px;
  }
  
  .dropdownOptions::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  .dropdownOptions::-webkit-scrollbar-thumb {
    background-color: #3C96D4; 
    border-radius: 4px;
  }

  .dropdown::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    border: solid;
    border-width: 0 2px 2px 0;
    border-radius: 1px;
    padding: 2px;
    pointer-events: none;
    border-color: #333;
    transform: translateY(-50%) rotate(45deg);
  }

  .dropdownDisabled .dropdownSelected {
    background-color: lightgray;
    cursor: not-allowed;
  }
  
  .dropdownDisabled .dropdownOptions {
    background-color: lightgray;
    cursor: not-allowed;
  }