.ClientMainContent {
    display: flex;
    flex-direction: column;
    padding-left: 24px;
    padding-right: 22px;
    margin-right: 2px;
    height: calc(100vh - 80px); /* Adjust the value based on the heights of the top navigation bar and any other elements that might affect the height */
    overflow: auto;
    overflow-x: hidden;
}

.ClientMainContent::-webkit-scrollbar {
    width: 9px;
}

.ClientMainContent::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

.ClientMainContent::-webkit-scrollbar-thumb {
    background-color: #3C96D4;
    border-radius: 5px;
}

.ClientHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 130px;
    margin-bottom: 20px;
}

.ClientTitle {
    font-family: 'Baloo Bhai', cursive;
    font-weight: bold;
    font-size: 24px;
}

.ClientTableFilters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.Filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.dropdownContainer {
    flex-grow: 0;
    /* Prevent the container from growing */
    flex-shrink: 0;
    /* Prevent the container from shrinking */
    width: 130px;
    /* Set a fixed width for the container */
    max-width: 130px;
    /* Ensure the width doesn't exceed 130px */
}

.FilterLabel {
    margin-right: 10px;
    font-weight: normal;
    color: #949494;
}

.FilterContainer {
    display: flex;
    align-items: center;
    width: 200px;
    /* You can adjust this width based on your requirements */
    margin-right: 23px;
    height: 25px;
    z-index: 9998;
}

.Btn {
    width: 83px;
    height: 25px;

    border-radius: 8px;
    background: #3C96D4;

    color: white;
    text-align: center;

    font-family: 'PT Sans', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;

    cursor: pointer;
}

.Btn:hover {
    background-color: #2a6d9e;
}

.AddBtn {
    width: 83px;
    height: 25px;
    /* flex-shrink: 1; */
    margin-right: 20px;

    border-radius: 8px;
    background: #3C96D4;

    color: white;
    text-align: center;

    font-family: 'PT Sans', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;

    cursor: pointer;
}

.AddDisabled {
    background-color: lightgray;
    cursor: not-allowed;
}

.AddBtn:hover {
    background-color: #2a6d9e;
}

.AddDisabled:hover {
    background-color: lightgray !important;
}

.SearchBar {
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}


.TableView {
    height: 60%;
}


.TableLayout {
    width: 100%;
    height: 317px;
    flex-shrink: 0;

    border-radius: 6px;
    border: 2px solid #ECEEF6;
    background: #FFF;
}


.flexDiv {
    display: flex;
    width: 100%;
}

.TimesheetTotals {
    width: 66%;
    height: 40px;
    flex-shrink: 0;

    border-radius: 6px;
    border: 2px solid #ECEEF6;
    background: #FFF;
}

.TimesheetPagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.TimesheetButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.PaginationDisplay {
    width: 83px;
    height: 25px;
    flex-shrink: 0;
    margin-inline: 10px;
    margin-bottom: 5px;

    border-radius: 8px;
    border: 2px solid #3C96D4;
    background: #FFF;

    color: #3C96D4;
    text-align: center;

    font-family: 'PT Sans', sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;

    cursor: pointer;
}

.paginationContainer {
    display: flex;
    justify-content: flex-end;
}

.paginationInfo {
    font-family: 'PT Sans', sans-serif;
    color: #949494;
    font-size: 12px;
    font-weight: bold;
}

.EmptyCell,
.Total {
    flex-grow: 0;
    /* Prevents the cells from growing */
    flex-shrink: 0;
    /* Prevents the cells from shrinking */
}

.ClientsOverlay {
    background-color: rgba(0, 0, 0, 0.4);
    /* Semi-transparent background */
    position: fixed;
    /* Cover the entire screen */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000000;
    /* Ensure it's above everything else */
    display: flex;
    justify-content: center;
    /* Center the content horizontally */
    align-items: center;
    /* Center the content vertically */
}

.ClientsResponseBox {
    background-color: #ffffff;
    min-width: 160px;
    max-width: 500px;
    min-height: 140px;
    max-height: 300px;
    border-radius: 20px;
    border: solid 2px #3C96D4;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.ClientsButton {
    justify-content: center;
    align-content: center;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #FFFFFF;
    background-color: #3C96D4;
    border: none;
    border-radius: 6px;
    width: 37px;
    height: 28px;
    cursor: pointer;
    margin-bottom: 20px;
}

.SuppliersButton:active {
    background-color: #ccc;
    /* Change the background color */
}

.SuppliersButton:hover {
    background-color: #165b8a;
    /* Change the background color */
}