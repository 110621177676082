.profileContainer {
  display: flex;
  flex-direction: column;
  height: calc(100vh); /* Subtract the height of the top navigation bar */
  position: relative;
  width: calc(100%); /* Subtract the width of the sidebar */
}

.profileContainer a {
    text-decoration: none;
    color: #292D32;
  }
  
  .profileContainer a:active {
    color: inherit;
  }
  
  /* Customize the scrollbar Sidebar */
  .profileContainer::-webkit-scrollbar {
    width: 10px; /* Adjust the width of the scrollbar */
  }
  
  /* Customize the scrollbar track */
  .profileContainer::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Set the background color of the track */
  }
  
  /* Customize the scrollbar thumb */
  .profileContainer::-webkit-scrollbar-thumb {
    background-color: #3C96D4; /* Set the background color of the thumb */
    border-radius: 5px; /* Add border-radius to make the thumb rounder */
  }
  
  /* Customize the scrollbar thumb on hover */
  .profileContainer::-webkit-scrollbar-thumb:hover {
    background-color: #3C96D4; /* Set the background color of the thumb on hover */
  }

  .navigationBar {
    width: 100%;
    padding-top: 80px;
  }

  .mainContent {
    margin-left: 24px;
    margin-right: 24px;
    overflow: auto;
  }