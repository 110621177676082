  @font-face {
    font-family: 'Baloo Bhai';
    src: url('./assets/BalooBhaiFontFamily/BalooBhai2-Bold.woff2') format('woff2'),
        url('./assets/BalooBhaiFontFamily/BalooBhai2-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Baloo Bhai';
    src: url('./assets/BalooBhaiFontFamily/BalooBhai2-Medium.woff2') format('woff2'),
        url('./assets/BalooBhaiFontFamily/BalooBhai2-Medium.woff') format('woff');
    font-weight: normal;
    font-style: medium;
}

@font-face {
    font-family: 'Baloo Bhai';
    src: url('./assets/BalooBhaiFontFamily/BalooBhai2-Regular.woff2') format('woff2'),
        url('./assets/BalooBhaiFontFamily/BalooBhai2-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}  