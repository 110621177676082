/* starting here */

.pageTitle {
  font-family: 'Baloo Bhai', cursive;
  font-weight: bold;
  font-size: 24px;
  margin-top: 11px;
}

.oppHead {
  font-family: "Baloo Bhai", cursive;
  font-weight: bold;
  font-size: 24px;
}

.scrollableColumn {
  height: 65vh;
  /* Adjust the height as needed */
  overflow-y: auto;
}

.scrollableColumn::-webkit-scrollbar {
  width: 9px;
}

.scrollableColumn::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.scrollableColumn::-webkit-scrollbar-thumb {
  background-color: #3C96D4;
  border-radius: 5px;
}

/* end here */

.opportunitiesContainer {
  display: flex;
  flex-direction: column;
  height: calc(100%);
  /* Subtract the height of the top navigation bar */
  position: relative;
  width: calc(97%);
  padding-left: 24px;
  overflow: hidden;
  /* Subtract the width of the sidebar */
}

.opportunitiesContainer a {
  text-decoration: none;
  color: #292d32;
}

/* Customize the scrollbar Sidebar */
.opportunitiesContainer::-webkit-scrollbar {
  width: 10px;
  /* Adjust the width of the scrollbar */
}

/* Customize the scrollbar track */
.opportunitiesContainer::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Set the background color of the track */
}

/* Customize the scrollbar thumb */
.opportunitiesContainer::-webkit-scrollbar-thumb {
  background-color: #3c96d4;
  /* Set the background color of the thumb */
  border-radius: 5px;
  /* Add border-radius to make the thumb rounder */
}

/* Customize the scrollbar thumb on hover */
.opportunitiesContainer::-webkit-scrollbar-thumb:hover {
  background-color: #3c96d4;
  /* Set the background color of the thumb on hover */
}

.mainContent {
  margin-left: 24px;
  margin-right: 200px;
  overflow: auto;
}

.title {
  font-family: "Baloo Bhai", cursive;
  font-weight: bold;
  font-size: 24px;
  margin-top: 27px;
}


/* Indicate empty lists */
.Card {
  border-radius: 6px;
  margin-bottom: 10px;
  border: 2px solid #ECEEF6;
  background: #fff;
  box-shadow: none !important;

  max-width: 240px;
  max-height: auto;
  flex-shrink: 0;
}

.CardContent {
  padding: 12px !important;
}

.cardTitle {
  color: #000;
  font-family: "PT Sans" !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  letter-spacing: 0.12px !important;
  margin-bottom: 6px !important;
}