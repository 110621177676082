.Sidebar {
  width: 185px;
  height: 100vh;
  background: #FFFFFF;
  border-right: 1px solid #E8E8E8;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
}

.SidebarList a {
  text-decoration: none;
  color: #292D32;
}

.listText {
  font-size: 14px;
  font-weight: bold;
}

.SidebarList a:active {
  color: inherit;
}

/* Customize the scrollbar Sidebar */
.Sidebar::-webkit-scrollbar {
  width: 5px; /* Adjust the width of the scrollbar */
}

/* Customize the scrollbar track */
.Sidebar::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Set the background color of the track */
}

/* Customize the scrollbar thumb */
.Sidebar::-webkit-scrollbar-thumb {
  background-color: #3C96D4; /* Set the background color of the thumb */
  border-radius: 5px; /* Add border-radius to make the thumb rounder */
}

/* Customize the scrollbar thumb on hover */
.Sidebar::-webkit-scrollbar-thumb:hover {
  background-color: #3C96D4; /* Set the background color of the thumb on hover */
}

.SidebarList {
  height: auto;
  padding: 0;
  width: 100%;
  flex: 1;
}
  
.row {
  position: relative;
  height: 33px;
  margin-top: 3.5px;
  margin-bottom: 3.5px;
  width: 100%;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 0px 9px 9px 0px;
}

.row.disabled {
  color: #D9D9D9;
}

.row.active:hover {
  background-color: #EAF2F9;
}

.row.ClickedOn {
  background-color: #EAF2F9;
  color: #3C96D4;
  font-weight: bold;
}

.row.ClickedOn::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 5px;
  height: 100%;
  background-color: #3C96D4;
  border-radius: 0px 10px 10px 0px;
}

.SidebarIcons {
  width: 19px; 
  height: 19px; 
}

.iconSpace {
  padding-top: 4px;
  margin-left: 30px;
  margin-right: 14px;
}

.iconDisabled {
  filter: invert(94%) sepia(0%) saturate(70%) hue-rotate(165deg) brightness(110%) contrast(70%);
}

.iconClickedOn {
  filter: invert(52%) sepia(86%) saturate(415%) hue-rotate(163deg) brightness(88%) contrast(88%);
}

.dottedLine {
  height: 1px;
  margin-left: calc((185px - 152px)/2);
  width: 142px;
  background-color: #E0E0E0;
  margin-top: 10px;
  margin-bottom: 10px;
}
