.invoicingContainer {
    display: flex;
    flex-direction: column;
    height: calc(100vh); /* Subtract the height of the top navigation bar */
    position: relative;
    width: calc(100%); /* Subtract the width of the sidebar */
}

.invoicingContainer a {
    text-decoration: none;
    color: #292D32;
}

/* Customize the scrollbar Sidebar */
.invoicingContainer::-webkit-scrollbar {
    width: 10px; /* Adjust the width of the scrollbar */
}

/* Customize the scrollbar track */
.invoicingContainer::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Set the background color of the track */
}

/* Customize the scrollbar thumb */
.invoicingContainer::-webkit-scrollbar-thumb {
    background-color: #3C96D4; /* Set the background color of the thumb */
    border-radius: 5px; /* Add border-radius to make the thumb rounder */
}

/* Customize the scrollbar thumb on hover */
.invoicingContainer::-webkit-scrollbar-thumb:hover {
    background-color: #3C96D4; /* Set the background color of the thumb on hover */
}