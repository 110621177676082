.numberInput {
    padding: 7px 4px;
    width: 35px;
    height: 8px;
    flex-shrink: 0;

    border-radius: 7px;
    border: 1px solid #3C96D4;
    font-family: 'PT Sans', sans-serif;
    -webkit-transition: 0.5s;
    transition: 0.2s;
    outline: none;

    font-size: 13px;
    text-align: center;
}
  
/* Apply styles for the focus state */
.numberInput:focus, .invalid-input:focus, .Weekend {
    border: 1px solid #c4c4c4;
}

/* Apply styles for the placeholder */
.numberInput::placeholder {
    color: #242424;
}

/* Apply styles for the invalid input animation */
.invalidInput {
    animation: pulsate 0.5s ease-in-out;
    animation-iteration-count: 1;
    border: 1px solid #3C96D4;
  }
  
@keyframes pulsate {
    0%, 100% {
        border-color: red;
    }
    50% {
        border-color: transparent;
    }
}
  