.MainContent {
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-right: 22px;
  margin-right: 2px;
  height: calc(100vh - 120px); /* Adjust the value based on the heights of the top navigation bar and any other elements that might affect the height */
  flex-grow: 1;
  overflow: auto;
  overflow-y: scroll;
}

.MainContent::-webkit-scrollbar {
  width: 9px;
}

.MainContent::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.MainContent::-webkit-scrollbar-thumb {
  background-color: #3C96D4;
  border-radius: 5px;
}

.notSoMainContent{
  display: flex;
  flex-direction: column;
  margin-right: 2px;
  height: calc(30vh+1px); /* Adjust the value based on the heights of the top navigation bar and any other elements that might affect the height */
  flex-grow: 1;
  overflow: auto;
  overflow-y: scroll;
}

.notSoMainContent::-webkit-scrollbar {
  width: 9px;
}

.notSoMainContent::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.notSoMainContent::-webkit-scrollbar-thumb {
  background-color: #3C96D4;
  border-radius: 5px;
}

.editIcon {
  position: absolute; /* This allows the img to be positioned anywhere within the div */
  top: 6px; /* Aligns the img with the top of the div */
  right: 6px; /* Aligns the img with the right of the div */
  z-index: 10; /* This ensures that the img is displayed above all other content in the div. Increase this value if the img is still behind other elements */  
}

.editIcon:hover {
  cursor: pointer;
}

.title {
  font-family: 'Baloo Bhai', cursive;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 12px;
  margin-top: 27px;
}

.titles {
  font-family: 'Baloo Bhai', cursive;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.unOpenedArrow {
  width: 2px;
  height: 2px;
  content: "";
  right: 12px;
  border: solid;
  border-width: 0 2px 2px 0;
  border-radius: 1px;
  padding: 2px;
}

.positionArrow {
  margin-right: 11px;
}

.titles:hover {
  cursor: pointer;
}

.profilePic {
  width: 126px;
  height: 126px;
  border-radius: 8px;
  margin: 0 12.5px 0 12.5px;
}

.tableContainer {
  border: solid 2px #ECEEF6;
  border-radius: 6px;
  overflow: visible;
  background-color: #ffffff;
}

.infoSector {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 12px;
  border-radius: 6px;
}

.infoBox {
  border: solid 2px #ECEEF6;
  padding: 16px 22px 16px 22px;
  background-color: #ffffff;
  margin: 0 12.5px 0 12.5px;
  position: relative;
}

.thinList {
  padding: 0px;
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 22px;
  margin: 0px;
}

.dataRow {
  display: contents;
  font-size: 12px;
}

.dataName {
  font-family: 'PT Sans', sans-serif;
  font-size: 14px;
  color: #949494;
  text-align: right;
  margin: 6px 0 6px 0;
}

.data {
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin: 6px 0 6px 0;
  text-align: left;
}

.table {
  width: 100%; 
  border-collapse: collapse;
  overflow: visible;
}

.th {
  font-size: 12px;
  font-weight: normal;
  font-family: 'PT Sans', sans-serif;
  color: #949494;
  height: 29px;
  text-align: left;
}

.tbody {
  font-size: 12px;
  font-weight: normal;
  font-family: 'PT Sans', sans-serif;
  color: #000000;
}

.th, .td {
  padding: 0 15px; /* Change the value to increase/decrease the space */
  height: 30px;
}

.tableRow {
  border-top: solid 1px #ECEEF6;
}

.MoreOptions {
  width: 14px;
  height: 14px;
}

.MoreOptions:hover {
  cursor: pointer;
}

.trEditIcon {
  width: 20px;
  height: 20px;
}

.trEditIcon:hover {
  cursor: pointer;
}

.tdImage {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 14px;
  margin: 8px 0 8px 8px;
}

.moreOptionsDiv{
  position: absolute;
  left: 17px;
  top: 12px;
  padding: 3px;
  width: 54px;
  max-height: 44px;
  border: 1px solid #3C96D4;
  border-radius: 8px;
  background-color: #ffffff;
  z-index: 99999;
  font-size: 13px;
  font-weight: bold;
}

.moreOptionsDivEdit, .moreOptionsDivDelete {
  border-radius: 6px;
  padding-left: 6px;
}

.moreOptionsDivEdit:hover, .moreOptionsDivDelete:hover {
  background-color: #ECEEF6;
  cursor: pointer;
}

.addButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.addButton {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 8px;
  background-color: #3C96D4;
  color: #ffffff;
  font-size: 13px;
  font-weight: bold;
  width: 83px;
  height: 25px;
}

.addButton:hover {
  background-color: #2a6d9e;
}

.imageSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.offBoardButton {
  margin-top: 21px;
  display: flex;
  background-color: #D9D9D9;
  color: #ffffff;
  width: 83px;
  height: 25px;
  border-radius: 8px;
  font-weight: bold;
  font-size: 13px;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.offBoardButton:hover {
  background-color: #EAF2F9;
}