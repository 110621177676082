.Overlay {
    background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent background */
    position: fixed; /* Cover the entire screen */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000000; /* Ensure it's above everything else */
    display: flex;
    justify-content: center; /* Center the content horizontally */
    align-items: center; /* Center the content vertically */
}

.ResponseBox {
    background-color: #ffffff;
    min-width: 160px;
    max-width: 500px;
    min-height: 140px;
    max-height: 300px;
    border-radius: 20px;
    border: solid 2px #3C96D4;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.Button {
    justify-content: center;
    align-content: center;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #FFFFFF;
    background-color: #3C96D4;
    border: none;
    border-radius: 6px;
    width: 37px;
    height: 28px;
    cursor: pointer;
    margin-bottom: 20px;
}

.Button:active {
    background-color: #ccc; /* Change the background color */
}

.Button:hover {
    background-color: #165b8a; /* Change the background color */
}